@import (reference) '@selectel/ui-kit-ant/styles/index.less';

.sui-submenu-dark() {
    @{sui-submenu_background}: @sui-color[@dark][@data-blue][@2];
    @{sui-submenu-border_color}: @sui-color[@dark][@data-blue][@3];
    @{sui-submenu-border_color__mobile}: @sui-color[@dark][@data-blue][@3];

    @{sui-submenu-item_color__base}: @sui-color[@dark][@data-blue][@10];
    @{sui-submenu-item_color__hover}: @sui-color[@dark][@signal-red][@6];
    @{sui-submenu-item_color__active}: @sui-color[@dark][@signal-red][@6];

    @{sui-submenu-item-border__focus}: @sui-color[@dark][@data-blue][@4];
}
