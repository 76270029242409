// Default styles
// ----------------------------------------------------------------------------
body
  color: var(--sui-typography_base)
  font-size: $text-size-base
  font-weight: $text-weight-base
  // TODO: this can lead to invalid styles for components of ui-kit-ant
  line-height: 1.25 !important

body
button
input
select
textarea
  font-family: $text-family-base

a
  color: var(--sui-typography_link)
  text-decoration: $link-decor-base

a:hover
  color: var(--sui-typography_link__hover)
  text-decoration: $link-decor-hover

pre
  border-radius: 4px
  background: $suiColor('base', 'xxs')


// Headings styles
// ----------------------------------------------------------------------------

.h1
.h2
.h3
.h4
  color: $header-color-base
  font-weight: $header-weight-base
  font-family: $header-family-base

.h1
  font-size $h1-font-size
  line-height $h1-line-height

.h2
  font-size $h2-font-size
  line-height $h2-line-height

.h3
  font-size $h3-font-size
  line-height $h3-line-height

.h4
  font-size $h4-font-size
  line-height $h4-line-height


// Typography helpers
// ----------------------------------------------------------------------------
.t-left
  text-align: left

.t-right
  text-align: right

.t-center
  text-align: center

.t-top
  vertical-align: top

.t-bottom
  vertical-align: bottom

.t-middle
  vertical-align: middle

.t-reg
  font-weight: 400 !important

.t-upp
  text-transform: uppercase

.t-low
  text-transform: lowercase

.t-s
  font-size: $text-size-small

.t-ms
  font-size: $text-size-middle

.t-xs
  font-size: $text-size-xsmall

.t-wrap
  text-overflow: ellipsis
  white-space: nowrap
  word-wrap: break-word
  overflow: hidden

.t-nowrap
  white-space nowrap

.t-break-all
  word-break: break-all

.t-break-word
  overflow-wrap: break-word


for $num in range(2, 12)
  .t-line-clamp-{$num}
    text-overflow: ellipsis
    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: $num
