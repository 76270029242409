@import '~angular-material/modules/js/virtualRepeat/virtualRepeat.css';
@import '~angular-material/modules/js/autocomplete/autocomplete.css';

// default styles
md-autocomplete
  height: auto !important;
  md-autocomplete-wrap
    height: auto !important;

  md-autocomplete-wrap.md-show-clear-button
    input
      padding-right: 42px;

    button
      position: absolute;
      right: 0;
      height: 100%;

.md-standard-list-container,
.md-virtual-repeat-container
  background: var(--sui-global_background);
  .selected
    background: var(--sui-select_option_background__hover);

.md-autocomplete-suggestions-container.md-not-found
  background-color: var(--sui-global_background);

.md-autocomplete-suggestions

  .md-autocomplete-suggestion, li
    font-size: 13px;
    line-height: 1.2;
    padding: 10px 15px;
    margin: 0;
    height: auto;
    min-height: 35px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  .md-autocomplete-suggestion:not([disabled]):hover,
  .md-autocomplete-suggestion:not([disabled]):focus
    background-color: var(--sui-select_option_background__hover);


md-autocomplete.sui-autocomplete-prevent-blink
  .md-input-messages-animation
    display: none;

  md-input-container.md-input-invalid
    .md-input
      border-color: var(--sui-select_border__error) !important

    &.md-input-focused .md-input
      border-color: var(--sui-select_border__error__hover)

md-autocomplete[md-floating-label] .md-show-clear-button button
  top: 22px;

md-autocomplete.sui-autocomplete-fix-clear-button[md-floating-label] .md-show-clear-button button
  top: 3px;

.sui-autocomplete-break-word-suggestions.md-autocomplete-suggestions
  .md-autocomplete-suggestion, li
    white-space: normal;
    word-break: break-word;
