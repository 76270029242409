@import 'light.theme.less';
@import 'dark.theme.less';
@import 'variables.less';
@import (reference) 'packages/panel-kit/src/lib/core/panel-themes-classes.less';

:root {
  .sui-ticket-footer-light();
}

@{dark-themes} {
  .sui-ticket-footer-dark();
}

.ticket-footer {
  background-color: var(@sui-footer_background-color);
  border-radius: 6px;
  border: 1px solid var(@sui-footer_border-color);
  padding: 24px;
  @media (max-width: @sui-mobile-max) {
    padding: 16px;
    border-radius: 0;
    margin-bottom: 0;
  }
}

.footer-textarea.ant-input {
  background-color: var(@sui-footer-textarea_background-color);
}

textarea.ant-input[disabled] {
  background-color: var(@sui-footer-textarea_background-color__disabled);
}

.is-drawer.ticket-footer {

    border-radius: 0;
    padding: 16px;
    margin-bottom: 0;


  .resolved-footer-btn {
    flex-grow: 1;
  }
}

@media (max-width: @sui-mobile-max) {
  .ant-upload-list-item-info {
    font-size: 14px;
  }
}
