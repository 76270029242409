@import '~textangular/dist/textAngular.css'

text-angular
  //font-family: 'Roboto', 'sans-serif'

  .ta-text
    height: 300px
    overflow: auto

  .ta-scroll-window > .ta-bind
    padding: 0

    p
      padding-bottom: 8px

    h1
      margin: 0
      line-height: 1
      font-weight: 700
      font-size: 28px

    h2
      margin: 0
      line-height: 1
      font-weight: 700
      font-size: 24px

    h3
      margin: 0
      line-height: 1
      font-weight: 700
      font-size: 20px

    h4
      margin: 0
      line-height: 1
      font-weight: 700
      font-size: 15px

    h5
      margin: 0
      line-height: 1
      font-weight: 700

    h6
      margin: 0
      line-height: 1
      font-weight: 700

  textarea
    height: 300px
    width: 100%
    resize: none !important
