.a
  &-fade-io
    &.ng-enter,
    &.ng-leave
      transition: opacity 200ms;

    &.ng-enter,
    &.ng-leave.ng-leave-active
      opacity: 0;
    &.ng-leave,
    &.ng-enter.ng-enter-active
      opacity: 1;
