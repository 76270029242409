$toggleItem(color)
  &:not([disabled])
    background-image: linear-gradient(to left, var(--sui-switch_handle__checked) 50%, var(--sui-switch_background__base) 50%)!important
  &:disabled
    background-image: linear-gradient(to left, var(--sui-switch_handle__checked_disabled) 50%, var(--sui-switch_background__disabled) 50%)!important
  &.m-solid
    border-color var(--sui-switch_handle__checked)
    &:not([disabled]):hover
      border-color var(--sui-switch_handle__checked)
    &:disabled
      border-color var(--sui-switch_handle__checked_disabled)
sui-toggle
  button
    &:focus
      border-color: var(--sui-button_primary-border__base)
    &:first-of-type:focus
      border-bottom-right-radius: 0 !important
      border-top-right-radius: 0 !important

    &:last-of-type:focus
      border-bottom-left-radius 0 !important
      border-top-left-radius 0 !important

    &.md-button
      margin 0 !important
      min-width 36px
      box-shadow none !important
      background-size: 206% 100%!important
      -webkit-transition border ease-in 0.15s, color 0.3s, background-position 0.3s!important
      -moz-transition border ease-in 0.15s, color 0.3s, background-position 0.3s!important
      transition border ease-in 0.15s,color 0.3s, background-position 0.3s!important
      &.m-bordered:disabled
        border-color: var(--sui-switch_border__base)
    &.m-solid
      transition border ease-in 0.45s,color 0.3s, background-position 0.3s!important
      -webkit-transition border ease-in 0.45s, color 0.3s, background-position 0.3s!important
      -moz-transition border ease-in 0.45s, color 0.3s, background-position 0.3s!important
    &.md-button:first-of-type
      border-right-width 0
      border-bottom-right-radius 0
      border-top-right-radius 0
      background-position 0 0 !important
      &:disabled
        background-size: 210% 100%!important
        background-position 3% 0 !important
      &.m-solid
        background-position 98% 0 !important
    &.md-button:last-of-type
      border-left-width 0
      border-bottom-left-radius 0
      border-top-left-radius 0
      background-position 0 0 !important
      &:disabled
        background-size: 210% 100%!important
      &.m-solid
        background-position -96% 0%!important

    &.m-background-info
      $toggleItem('info')

.toggle-unchecked:not([disabled])
  background: var(--sui-switch_background__base)
