@import '~angular-material/modules/js/chips/chips.css'

// Variables

$chip-margin ?= 3px
$chip-padding ?= 6px
$chip-height ?= 24px
$chip-border-radius ?= 4px
$chip-icon-size ?= 13px

$chips-input-height ?= $chip-height + 2 * $chip-margin
$chips-input-font-size ?= 15px
$chips-input-padding = 0px 12px - $chip-margin

//Mixins

suiCommonChip($color, $background)
  color: var(--sui-tag_text__base)
  background: var(--sui-tag_background__base)

  md-icon
    color: var(--sui-tag_close-icon__base) !important

  button:hover
    md-icon
      color: var(--sui-tag_close-icon__hover) !important
  button:focus
    border-radius: 1px;
    box-shadow: 0 0 0 4px var(--sui-tag_border__focus)
    md-icon
      color: var(--sui-tag_close-icon__hover) !important

md-chips
  .md-chips
    border: 1px solid $suiColor('base', 's')
    border-radius: $radius-base !important
    height: auto
    min-height: 36px
    display: flex!important
    padding: 3px!important
    flex-direction: row!important
    flex-flow: row wrap
    justify-content: flex-start!important
    align-items: flex-start!important

    .md-chip-input-container
      margin: 0!important
      height: $chips-input-height - 2px
      line-height: $chips-input-height
      min-width: 150px
      flex: 1

      md-autocomplete,
      md-autocomplete-wrap
        height: $chips-input-height!important

      md-autocomplete
        .md-input:focus,
        .md-input:hover,
        .md-input
          border: none !important;
          box-shadow: none !important

      input
        height: $chips-input-height!important
        line-height: $chips-input-height!important
        font-size: $chips-input-font-size
        box-sizing: border-box
        width: 100%
        padding: $chips-input-padding!important
        border: none!important

        &::placeholder,
        &::-webkit-input-placeholder
          color: $suiColor('base')
          text-align: left
          vertical-align: baseline

    md-chip
      suiCommonChip(black, $suiColor('base'))
      border-radius: $chip-border-radius!important
      margin: $chip-margin!important
      font-size: $text-size-small
      height: $chip-height
      line-height: $chip-height
      padding: 0 $chip-padding

      &.m-info
        suiCommonChip(var(--sui-tag_text__white), var(--sui-tag_background__geekblue))
      &.m-danger
        suiCommonChip(var(--sui-tag_text__white), var(--sui-tag_background__red))
      &.m-warning
        suiCommonChip(var(--sui-tag_text__white), var(--sui-tag_background__volcano))
      &.m-success
        suiCommonChip(var(--sui-tag_text__white), var(--sui-tag_background__green))

      .md-chip-remove-container
        line-height: $chip-height
        height: $chip-height

      .md-chip-remove
        width: $chip-height!important
        height: $chip-height!important
        display: block

        md-icon
          min-height: $chip-icon-size
          min-width: $chip-icon-size
          height: $chip-icon-size
          width: $chip-icon-size
          line-height: $chip-icon-size

    &.md-removable
      md-chip
        padding-right: $chip-height

        .md-chip-content
          padding-right: 0!important

    &.md-readonly
      color: var(--sui-input_color__disabled)
      border-color: var(--sui-input_border_color__disabled)
      background-color: var(--sui-input_background-color__disabled)

      md-chip
        opacity: .5

  &.ng-invalid.ng-dirty .md-chips
    border-color: var(--sui-input_border-color__error)

  &:not(.ng-invalid.ng-dirty) .md-chips.md-focused
    border-color: var(--sui-input_border_color__active)
    box-shadow: 0 0 0 4px var(--sui-input_box-shadow__active)
