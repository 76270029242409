// Prefixes for generated HTML classes
$PREFIX_FONT_COLOR ?= '.f'
$PREFIX_FONT_HOVER_COLOR ?= '.f-h'
$PREFIX_BACKGROUND ?= '.s-bg'
$PREFIX_BACKGROUND_HOVER_COLOR ?= '.s-h'
$PREFIX_RADIUS ?= '.s-rad'
$PREFIX_RADIUS_TOP ?= '.s-radt'
$PREFIX_RADIUS_LEFT ?= '.s-radl'
$PREFIX_RADIUS_RIGHT ?= '.s-radr'
$PREFIX_RADIUS_BOTTOM ?= '.s-radb'
$PREFIX_BORDER ?= '.s-bd'
$PREFIX_BORDER_TOP ?= '.s-bdt'
$PREFIX_BORDER_LEFT ?= '.s-bdl'
$PREFIX_BORDER_RIGHT ?= '.s-bdr'
$PREFIX_BORDER_BOTTOM ?= '.s-bdb'
$CLASS_DARK_THEME ?= '.sui-dark-theme, .sui-green-dark-theme'

$light-theme-bg ?= $suiNewColor('gray', '1', 'neutral')
$dark-theme-bg ?= $suiNewColor('data-blue', '2', 'dark')

invertedColor($bg, $newBg, $color)
  $hue = hue($color)
  $sat = saturation($newBg) + saturation($bg) - saturation($color)
  if saturation($color) == 0 && $hue == 0
    $sat = saturation($newBg) - 1
    $hue = hue($newBg)
  else if $sat < 0
    $sat = saturation($color)
  $lig = lightness($newBg) + lightness($bg) - lightness($color)

  hsl($hue, $sat, $lig)

// Function for generate font skins like:
// '.f-success-xs'
generateFontSkins($name, $palette, $color)
  $selector = $PREFIX_FONT_COLOR + "-" + $name
  if $power isnt $KEY_DEFAULT
    $selector += '-' + $power
  {$selector}
    color $color !important

// Function for generate font hover skins like:
// '.f-h-success-xs'
generateFontHoverSkins($name, $palette, $color)
  $selector = $PREFIX_FONT_HOVER_COLOR + "-" + $name
  if $power isnt $KEY_DEFAULT
    $selector += '-' + $power
  {$selector}
    &:not([disabled]):hover
      color $color !important

// Function for generate background skins like:
// '.s-bg-info-xxs'
generateBackgroundSkins($name, $power, $color)
  $selector = $PREFIX_BACKGROUND + "-" + $name
  if $power isnt $KEY_DEFAULT
    $selector += '-' + $power

  {$selector}
    background $color !important

// Function for generate background hover skins like:
// '.s-h-info-xxs'
generateBackgroundHoverSkins($name, $power, $color)
  $selector = $PREFIX_BACKGROUND_HOVER_COLOR + "-" + $name
  if $power isnt $KEY_DEFAULT
    $selector += '-' + $power
  {$selector}
    &:not([disabled]):hover
      background $color !important

// Function for generate border skins like:
// '.s-bdb-warning-xl'
generateBorderSkins($name, $power, $color)
  $suffix = '-' + $name
  if $power isnt $KEY_DEFAULT
    $suffix += '-' + $power

  {$PREFIX_BORDER + $suffix}
    border $border-base solid $color !important
  {$PREFIX_BORDER_TOP + $suffix}
    border-top $border-base solid $color !important
  {$PREFIX_BORDER_LEFT + $suffix}
    border-left $border-base solid $color !important
  {$PREFIX_BORDER_RIGHT + $suffix}
    border-right $border-base solid $color !important
  {$PREFIX_BORDER_BOTTOM + $suffix}
    border-bottom $border-base solid $color !important

  {$PREFIX_BORDER + '-l' + $suffix}
    border $border-large solid $color !important
  {$PREFIX_BORDER_TOP + '-l' + $suffix}
    border-top $border-large solid $color !important
  {$PREFIX_BORDER_LEFT + '-l' + $suffix}
    border-left $border-large solid $color !important
  {$PREFIX_BORDER_RIGHT + '-l' + $suffix}
    border-right $border-large solid $color !important
  {$PREFIX_BORDER_BOTTOM + '-l' + $suffix}
    border-bottom $border-large solid $color !important

  {$PREFIX_BORDER + '-xl' + $suffix}
    border $border-xlarge solid $color !important
  {$PREFIX_BORDER_TOP + '-xl' + $suffix}
    border-top $border-xlarge solid $color !important
  {$PREFIX_BORDER_LEFT + '-xl' + $suffix}
    border-left $border-xlarge solid $color !important
  {$PREFIX_BORDER_RIGHT + '-xl' + $suffix}
    border-right $border-xlarge solid $color !important
  {$PREFIX_BORDER_BOTTOM + '-xl' + $suffix}
    border-bottom $border-xlarge solid $color !important

// Generate all skins based on colors
// from theme.json
for $name, $palette in $THEME.color
  for $power in $palette
    generateFontSkins($name, $power, $suiColor($name, $power))
    generateFontHoverSkins($name, $power, $suiColor($name, $power))
    generateBorderSkins($name, $power, $suiColor($name, $power))
    generateBackgroundSkins($name, $power, $suiColor($name, $power))
    generateBackgroundHoverSkins($name, $power, $suiColor($name, $power))

    {$CLASS_DARK_THEME}
      generateFontSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiColor($name, $power)))
      generateFontHoverSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiColor($name, $power)))
      generateBorderSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiColor($name, $power)))
      generateBackgroundSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiColor($name, $power)))
      generateBackgroundHoverSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiColor($name, $power)))


// Generate skins based on new design colors
for $name, $palette in $NEW_DESIGN_COLORS.light
  for $power in $palette
    generateFontSkins($name, $power, $suiNewColor($name, $power))
    generateBorderSkins($name, $power, $suiNewColor($name, $power))
    generateBackgroundSkins($name, $power, $suiNewColor($name, $power))

    {$CLASS_DARK_THEME}
      generateFontSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiNewColor($name, $power)))
      generateBorderSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiNewColor($name, $power)))
      generateBackgroundSkins($name, $power, invertedColor($light-theme-bg, $dark-theme-bg, $suiNewColor($name, $power)))

// Main background, like body
{$PREFIX_BACKGROUND + '-main'}
  background $background-main

// Contrast color
{$PREFIX_FONT_COLOR + '-contrast'}
  color $text-color-contrast

// Radius skins
{$PREFIX_RADIUS}
  border-radius $radius-base !important
{$PREFIX_RADIUS_TOP}
  border-radius $radius-base $radius-base 0 0 !important
{$PREFIX_RADIUS_LEFT}
  border-radius $radius-base 0 0 $radius-base !important
{$PREFIX_RADIUS_RIGHT}
  border-radius 0 $radius-base $radius-base 0 !important
{$PREFIX_RADIUS_BOTTOM}
  border-radius 0 0 $radius-base $radius-base !important

{$PREFIX_RADIUS + "-s"}
  border-radius $radius-small !important
{$PREFIX_RADIUS_TOP + "-s"}
  border-radius $radius-small $radius-small 0 0 !important
{$PREFIX_RADIUS_LEFT + "-s"}
  border-radius $radius-small 0 0 $radius-small !important
{$PREFIX_RADIUS_RIGHT + "-s"}
  border-radius 0 $radius-small $radius-small 0 !important
{$PREFIX_RADIUS_BOTTOM + "-s"}
  border-radius 0 0 $radius-small $radius-small !important

{$PREFIX_RADIUS + "-l"}
  border-radius $radius-large !important
{$PREFIX_RADIUS_TOP + "-l"}
  border-radius $radius-large $radius-large 0 0 !important
{$PREFIX_RADIUS_LEFT + "-l"}
  border-radius $radius-large 0 0 $radius-large !important
{$PREFIX_RADIUS_RIGHT + "-l"}
  border-radius 0 $radius-large $radius-large 0 !important
{$PREFIX_RADIUS_BOTTOM + "-l"}
  border-radius 0 0 $radius-large $radius-large !important

{$PREFIX_RADIUS + "-f"}
  border-radius $radius-full !important
{$PREFIX_RADIUS_TOP + "-f"}
  border-radius $radius-full $radius-full 0 0 !important
{$PREFIX_RADIUS_LEFT + "-f"}
  border-radius $radius-full 0 0 $radius-full !important
{$PREFIX_RADIUS_RIGHT + "-f"}
  border-radius 0 $radius-full $radius-full 0 !important
{$PREFIX_RADIUS_BOTTOM + "-f"}
  border-radius 0 0 $radius-full $radius-full !important

{$PREFIX_RADIUS + "-0"}
  border-radius 0 !important
{$PREFIX_RADIUS_TOP + "-0"}
  border-top-left-radius 0 !important
  border-top-right-radius  0 !important
{$PREFIX_RADIUS_LEFT + "-0"}
  border-top-left-radius 0 !important
  border-bottom-left-radius 0 !important
{$PREFIX_RADIUS_RIGHT + "-0"}
  border-top-right-radius 0 !important
  border-bottom-right-radius 0 !important
{$PREFIX_RADIUS_BOTTOM + "-0"}
  border-bottom-left-radius 0 !important
  border-bottom-right-radius 0 !important

.s-o-hidden {
  overflow: hidden;
}

.s-o-visible {
  overflow: visible !important;
}
