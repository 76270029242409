// Variables
// ----------------------------------------------------------------------------
sqr(x)
  return x * x;

sqrt(x)
  return math(x, 'sqrt');

diagonal(x)
  return sqrt(sqr(x) + sqr(x));

$background = var(--sui-popover_background-color__info);
$shadow_arrow = -1px 1px 2px 0 var(--sui-popover_arrow_box-shadow__info);
$margin = $suiSize('xxs');
$cornerSize = 10px;
$cornerMargin = diagonal($cornerSize) / 2 + $margin;

// Default popover styles
// ----------------------------------------------------------------------------
sui-popover
  display: flex;

  &.sui-opened
    z-index: 100;

sui-popover-button
  cursor: pointer;

  .sui-popover-button-chevron
    display: flex;
    padding-left: 2px;
    padding-right: 2px;

    md-icon
      width: 12px;
      height: 12px;

addCorner($top, $left, $rotate, $bg = $background)
  &:before
    content: '';
    display: block;
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    background: $bg;
    width: $cornerSize;
    height: $cornerSize;
    border: 1px solid transparent;
    top: $top;
    left: $left;
    transform: translate(-50%, -50%) rotate($rotate);
    box-shadow: $shadow_arrow;


sui-popover-content
  display: block;
  background: $background !important;
  box-shadow:  0 2px 4px 0 var(--sui-popover_box-shadow__info__1), 0 0 2px 0 var(--sui-popover_box-shadow__info__2);
  padding: $suiSize('xs') $suiSize('s');
  border-radius: $radius-base;

  &.sui-origin-top
    transform-origin: center bottom;
    margin-top: -1 * $margin;

    &.sui-corner
      margin-top: -1 * $cornerMargin;
      addCorner(100%, 50%, 315deg);

  &.sui-origin-right
    transform-origin: left center;
    margin-left: $margin;

    &.sui-corner
      margin-left: $cornerMargin;
      addCorner(50%, 0%, 45deg);

  &.sui-origin-bottom
    transform-origin: center top;
    margin-top: $margin;

    &.sui-corner
      margin-top: $cornerMargin;
      addCorner(0%, 50%, 135deg);

  &.sui-origin-left
    transform-origin: right center;
    margin-left: -1 * $margin;

    &.sui-corner
      margin-left: -1 * $cornerMargin;
      addCorner(50%, 100%, 225deg);


sui-popover-content-bubble
  $background = var(--sui-popover_background-color__base);
  $shadow_arrow = none;
  display: block;
  background: $background !important;
  font-size: 14px;
  color: var(--sui-popover_content_color__base);
  max-width: 328px;
  box-shadow: none;
  padding: 12px 16px;
  border-radius: $radius-base;

  @media (max-width: 575px) {
    padding: 12px;
    margin-left: 16px;
    margin-right: 16px;
  }

  a {
    color: var(--sui-popover_link_color__base);

    &:hover {
      color: var(--sui-popover_link_color__base__hover);
    }
  }

  &.sui-origin-top
    transform-origin: center bottom;
    margin-top: -1 * $cornerMargin;
    addCorner(100%, 50%, 315deg, $background);

  &.sui-origin-right
    transform-origin: left center;
    margin-left: $cornerMargin;
    addCorner(50%, 0%, 45deg, $background);

  &.sui-origin-bottom
    transform-origin: center top;
    margin-top: $cornerMargin;
    addCorner(0%, 50%, 135deg, $background);

  &.sui-origin-left
    transform-origin: right center;
    margin-left: -1 * $cornerMargin;
    addCorner(50%, 100%, 225deg, $background);
