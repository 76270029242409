@import "~angularjs-slider/dist/rzslider.css"

.rzslider
  .rz-bar-wrapper
    cursor: pointer
    .rz-bar
      height 2px

  &:not([disabled])
    .rz-bar-wrapper
      cursor: pointer
      .rz-bar
        background-color var(--sui-slider_rail_color__base)
        &.rz-selection
          background-color var(--sui-slider_track_background_color__base)
    &:hover
      .rz-bar-wrapper
        .rz-selection
          background-color: var(--sui-slider_track_background_color__hover)

  .rz-pointer
    height 14px
    width 14px
    top -6px
    background-color var(--sui-global_control-background)
    border: 2px solid var(--sui-slider_handle_border)

    &:hover
      background-color var(--sui-slider_handle_background_color__hover)
    &:focus
      box-shadow 0 0 0 8px var(--sui-slider_handle_shadow)
    &:after
      display none

  .rz-bubble
    color: var(--sui-slider-handle_text_color__base)

  &[disabled]
    .rz-bar-wrapper
      .rz-bar
        background-color var(--sui-slider_rail_background_color__disabled)
        &.rz-selection
          background-color var(--sui-slider_track_background_color__disabled)
    .rz-pointer
      height 10px
      width 10px
      top -4px
      background-color var(--sui-global_control-background)
      border-color: var(--sui-slider_track_background_color__disabled)
