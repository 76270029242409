$PREFIX_MARGIN ?= '.l-m'
$PREFIX_MARGIN_TOP ?= '.l-mt'
$PREFIX_MARGIN_LEFT ?= '.l-ml'
$PREFIX_MARGIN_RIGHT ?= '.l-mr'
$PREFIX_MARGIN_BOTTOM ?= '.l-mb'
$PREFIX_MARGIN_VERTICAL ?= '.l-mtb'
$PREFIX_MARGIN_HORIZONTAL ?= '.l-mlr'

$PREFIX_PADDING ?= '.l-p'
$PREFIX_PADDING_TOP ?= '.l-pt'
$PREFIX_PADDING_LEFT ?= '.l-pl'
$PREFIX_PADDING_RIGHT ?= '.l-pr'
$PREFIX_PADDING_BOTTOM ?= '.l-pb'
$PREFIX_PADDING_VERTICAL ?= '.l-ptb'
$PREFIX_PADDING_HORIZONTAL ?= '.l-plr'

$PREFIX_LAYOUT_POOL ?= '.l-pool'
$PREFIX_LAYOUT_LINE ?= '.l-line'
$PREFIX_LAYOUT_STACK ?= '.l-stack'
$PREFIX_LAYOUT_WIDTH ?= '.l-w'
$PREFIX_LAYOUT_HEIGHT ?= '.l-h'

.l-d-b {
  display: block;
}
.l-d-i {
  display: inline;
}
.l-d-ib {
  display: inline-block;
}


// Function for generate full set of margins
// and paddings + layout types with defined child
// offset
generateOffsets($name, $size)
  $suffix = ''
  if $name isnt $KEY_DEFAULT
    $suffix += '-' + $name
  {$PREFIX_PADDING + $suffix}
    padding $size !important
  {$PREFIX_PADDING_TOP + $suffix}
    padding-top $size !important
  {$PREFIX_PADDING_LEFT + $suffix}
    padding-left $size !important
  {$PREFIX_PADDING_RIGHT + $suffix}
    padding-right $size !important
  {$PREFIX_PADDING_BOTTOM + $suffix}
    padding-bottom $size !important
  {$PREFIX_PADDING_VERTICAL + $suffix}
    padding-top $size !important
    padding-bottom $size !important
  {$PREFIX_PADDING_HORIZONTAL + $suffix}
    padding-left $size !important
    padding-right $size !important
  {$PREFIX_MARGIN + $suffix}
    margin $size !important
  {$PREFIX_MARGIN_TOP + $suffix}
    margin-top $size !important
  {$PREFIX_MARGIN_LEFT + $suffix}
    margin-left $size !important
  {$PREFIX_MARGIN_RIGHT + $suffix}
    margin-right $size !important
  {$PREFIX_MARGIN_BOTTOM + $suffix}
    margin-bottom $size !important
  {$PREFIX_MARGIN_VERTICAL + $suffix}
    margin-top $size !important
    margin-bottom $size !important
  {$PREFIX_MARGIN_HORIZONTAL + $suffix}
    margin-left $size !important
    margin-right $size !important

generateLayouts($name, $size)
  $suffix = ''
  if $name isnt $KEY_DEFAULT
    $suffix += '-' + $name
  {$PREFIX_LAYOUT_WIDTH + $suffix}
    width $size !important
  {$PREFIX_LAYOUT_HEIGHT + $suffix}
    height $size !important
  {$PREFIX_LAYOUT_LINE + $suffix} > * + *
    margin-left $size !important
  {$PREFIX_LAYOUT_STACK + $suffix} > * + *
    margin-top $size !important
  {$PREFIX_LAYOUT_POOL + $suffix}
    margin-right -($size) !important
    margin-bottom -($size) !important
    padding-bottom 0.1px
    display: flex
    flex-wrap: wrap
  {$PREFIX_LAYOUT_POOL + $suffix} > *
    margin-right $size !important
    margin-bottom $size !important

// Generate all offsets and layouts based on
// $THEME.size
for $name, $size in $THEME.size
  generateOffsets($name, $size)
  generateLayouts($name, $size)

