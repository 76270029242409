@import (reference) 'packages/panel-kit/src/lib/variables.less';

.blue-link {
  color: black;
}

.blue-link:hover {
  color: #0c4d69;
}

.resource-editable-name {
  display: block;

  button[aria-label="edit"] {
    display: none;
  }
}

cdn-container-input {
  display: block;
}

.auto-pointer-events {
  pointer-events: auto !important;
}

.label-flex-row {
  display: flex!important;
  flex-direction: row;
}
