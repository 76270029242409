@import '../input/input';

$input-height = 36px;
$icon-padding = 6px;
$icon-small = 16px;
$icon-base = 20px;
$icon-large = 28px;

suiSearchBarInputPadding($icons-count, $icon-size)
  icons = $icon-size * $icons-count;
  paddings = $icon-padding * $icons-count * 2;
  borders = unit($icons-count - 1, px);
  return icons + paddings + borders;


suiSearchBarInput($name="base")
  suiInputSize('base');
  padding-right: suiSearchBarInputPadding(1, lookup('$icon-' + $name));
  &:not(.ng-empty)
    padding-right: suiSearchBarInputPadding(2, lookup('$icon-' + $name));

sui-search-bar
  display: block;

  // ===== input =====
  md-input-container
    width: 100%;

  md-input-container .md-input
    suiSearchBarInput('base');

  &.m-s md-input-container .md-input
    suiSearchBarInput('small');

  &.m-l md-input-container .md-input
    suiSearchBarInput('large');

  // ===== icons =====
  .icons
    position: absolute;
    top: 0;
    right: 0;
    height: $input-height;

  .md-input[readonly] ~ .icons,
  .md-input[disabled] ~ .icons
    pointer-events: none;

    & md-icon, & md-progress-circular
      color: rgba($suiColor('base', 's'))

  .icons > *
    box-sizing: content-box;
    padding: 0 $icon-padding;

    &:not(:last-child)
      border-right: 1px solid $suiColor('base', 's');

    & > svg
      right: auto;
      left: auto;

  md-icon,
  md-progress-circular
    width: $icon-base !important;
    height: $icon-base;

  &.m-s md-icon,
  &.m-s md-progress-circular
    width: $icon-small !important;
    height: $icon-small;

  &.m-l md-icon,
  &.m-l md-progress-circular
    width: $icon-large !important;
    height: $icon-large;
