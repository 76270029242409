@import '~angular-material/modules/js/tabs/tabs.css'

// Variables
// ----------------------------------------------------------------------------
$tab-bar-height = 2px
$tab-border-color = var(--sui-tabs_content-border-color)
$tab-label-color = var(--sui-tabs_tab-color)
$tab-label-active-color = var(--sui-tabs_tab-color__active)


// Mixins
// ----------------------------------------------------------------------------
tabs()
  md-ink-bar
    color: var(--sui-tabs_tab-color__active)
    background: var(--sui-tabs_tab-color__active)
  .md-tab:not(.md-active, [disabled])
    &:hover
      color: var(--sui-tabs_tab-color__hover) !important
  .md-tab.md-active
  .md-tab.md-active md-icon
  .md-tab.md-focused
  .md-tab.md-focused md-icon
    color: var(--sui-tabs_tab-color__active)



// Default tabs styles
// ----------------------------------------------------------------------------
md-tabs

  border-radius: 0

  .md-tab
    text-transform: none
    padding: 15px 10px
    font-size: 15px
    font-weight: 400
    line-height: 16px
    &:hover
      color: var(--sui-tabs_tab-color)

  &.m-menu
    .md-tab
      padding: 0
    .md-tab > a
    .md-tab > span
      display: inline-block
      padding: 15px 10px


  md-ink-bar
    height: $tab-bar-height
    border-radius 1px

  md-tab-content
    transition none !important

  md-tabs-wrapper
    background-color: transparent
    border-color: $tab-border-color

  .md-paginator md-icon
    color: var(--sui-tabs_tab-color__active)

  .md-tab
    color: $tab-label-color

  .md-tab[disabled]
  .md-tab[disabled] md-icon
    color: var(--sui-tabs_tab-color__disabled)

  .md-tab.md-focused
    box-shadow: inset 0 0 0 2px var(--sui-tabs_tab-border-color__focus);


// Default tabs themes
// ----------------------------------------------------------------------------
md-tabs
  &
    tabs()
