sui-editable, sui-editable-value, sui-editable-input, sui-editable-context
  min-width: 0;
  display: block;

  md-input-container, ng-messages, ng-message
    display: block;

md-menu-item sui-editable-context
  padding: 0;
  margin: 0;

  & > .md-button
    min-height: 34px;
    padding: 0 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    display: inline-block;
    border-radius: 0;
    margin: auto 0;
    text-transform: none;
    height: 100%;
    width: 100%;

    md-icon + ng-transclude
      margin-left: 4px;
