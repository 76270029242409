@import '~angular-material/modules/js/input/input.css'


// Variables
// ----------------------------------------------------------------------------

$input-small-font ?= 13px
$input-small-height ?= 36px
$input-small-padding ?= 0px 12px

$input-base-font ?= 15px
$input-base-height ?= 36px
$input-base-padding ?= 0px 12px

$input-large-font ?= 18px
$input-large-height ?= 36px
$input-large-padding ?= 0px 12px


// Mixins
// ----------------------------------------------------------------------------

suiInputSize($name="base")
  height: lookup('$input-' + $name + '-height')
  padding: lookup('$input-' + $name + '-padding')
  font-size: lookup('$input-' + $name + '-font')
  line-height: lookup('$input-' + $name + '-font') + 1px


// Default styles
// ----------------------------------------------------------------------------

md-input-container
  margin: 0
  padding: 0

  &.md-icon-left,
  &.md-icon-right
    md-icon
      height: 36px
      top: 19px

  &.md-icon-left
    .md-input
      padding-left: 39px

    md-icon
      left: 10px

  &.md-icon-right
    .md-input
      padding-right: 39px

    md-icon
      right: 10px !important

  label.md-placeholder
    opacity: 1

  label:not(.md-container-ignore)
    display: block
    position: relative
    padding: 0 !important
    font-weight: 400
    margin-bottom: 4px
    transform: none !important // disable material floating label animation
    bottom: auto // Firefox fix

  label:after
    content: '' !important

  .md-input
    float: none
    height: 36px
    padding: 0 8px
    border-radius: $radius-base
    border-width: 1px !important
    border-style: solid !important
    color: var(--sui-input_color__base)
    background-color: var(--sui-input_background-color__base)

    &[disabled]
      color var(--sui-input_color__disabled)
      background-color var(--sui-input_background-color__disabled)
      border-color var(--sui-input_border_color__disabled)
      &:hover
        cursor not-allowed

    &:not([disabled]):hover
      border-color: var(--sui-input_border_color__hover)

    &::-webkit-input-placeholder
    &::placeholder
      color: var(--sui-input_placeholder_color__base)
      line-height: normal

    &::-moz-placeholder
      line-height: 34px

    suiInputSize("base")
    &.m-s
      suiInputSize("small")
    &.m-l
      suiInputSize("large")

  .md-errors-spacer
    min-height: 0

  .input-icon-container
    position: relative

    md-icon
      position: absolute
      top: 0

// Default input theme
// ----------------------------------------------------------------------------

md-input-container .md-input, .md-input
  color: var(--sui-input_color__base)
  border-color: var(--sui-input_border_color__base)
  text-shadow: none

// TODO: check icons
//md-input-container > md-icon
//	color: '{{foreground-1}}'

md-input-container label,
md-input-container .md-placeholder
  text-shadow: none
  color: var(--sui-form-label-color)
  font-size: $text-size-small

md-input-container .sui-tip
  font-size: $text-size-small
  color: var(--sui-form-helper-color)
  margin-bottom: 6px

md-input-container ng-messages :not(.md-char-counter)
md-input-container [ng-messages] :not(.md-char-counter)
md-input-container ng-message :not(.md-char-counter)
md-input-container data-ng-message :not(.md-char-counter)
md-input-container x-ng-message :not(.md-char-counter)
md-input-container [ng-message] :not(.md-char-counter)
md-input-container [data-ng-message] :not(.md-char-counter)
md-input-container [x-ng-message] :not(.md-char-counter)
md-input-container [ng-message-exp] :not(.md-char-counter)
md-input-container [data-ng-message-exp] :not(.md-char-counter)
md-input-container [x-ng-message-exp] :not(.md-char-counter)
  color: var(--sui-form-error-color)

md-input-container:not(.md-input-invalid).md-input-has-value label
  color: var(--sui-input_color__base)

md-input-container.md-input-has-value input:not(:focus)
  color: var(--sui-input_color__base)

md-input-container:not(.md-input-invalid).md-input-focused .md-input
  border-color: var(--sui-input_border_color__active)
  box-shadow: 0 0 0 4px var(--sui-input_box-shadow__active);

md-input-container:not(.md-input-invalid).md-input-focused label
  color: var(--sui-input_color__base)

md-input-container.md-input-invalid .md-input
  border-color: var(--sui-input_border-color__error)

md-input-container.md-input-invalid.md-input-focused label
  color: var(--sui-input_border-color__error)

md-input-container.md-input-invalid ng-message
md-input-container.md-input-invalid data-ng-message
md-input-container.md-input-invalid x-ng-message
md-input-container.md-input-invalid [ng-message]
md-input-container.md-input-invalid [data-ng-message]
md-input-container.md-input-invalid [x-ng-message]
md-input-container.md-input-invalid [ng-message-exp]
md-input-container.md-input-invalid [data-ng-message-exp]
md-input-container.md-input-invalid [x-ng-message-exp]
md-input-container.md-input-invalid .md-char-counter
  color: var(--sui-input_border-color__error)

md-input-container.label-overflow-visible
  label
    overflow: visible !important

// Prefixes and postfixes

inputAffix($name="base")
  display: inline-block
  position: absolute
  top: 0
  padding: lookup('$input-' + $name + '-padding')
  height: lookup('$input-' + $name + '-height')
  font-size: lookup('$input-' + $name + '-font')
  line-height: lookup('$input-' + $name + '-height')

.input-container
  position: relative;

  input-prefix, .input-prefix, [input-prefix],
  input-postfix, .input-postfix, [input-postfix]
    inputAffix("base")

    &.m-s
      inputAffix("small")

    &.m-l
      inputAffix("large")

    &:not([interactive-affix])
      pointer-events: none

  input-prefix, .input-prefix, [input-prefix]
    left: 0

  input-postfix, .input-postfix, [input-postfix]
    right: 0
