.app-info-splash-text {
  a {
    margin-top: 8px;
    display: inline-block;
  }

  a + a {
    margin-top: 4px;
  }
}
