@import (reference) '@selectel/ui-kit-ant/styles/theme/button/button.variables.less';
@import (reference) '@selectel/ui-kit-ant/styles/theme/pagination/pagination.variables.less';

pagination, sui-pagination {
  display: block;

  .pagination-button {
    font-size: 16px;
    transition: none !important;
    &.m-solid:not([disabled]) {
      &:hover,
      &:focus {
        color: var(@sui-button_primary-color__base);
        background-color: var(@sui-button_primary-background__base);
        border-color: var(@sui-button_primary-border__base)
      }
    }
    &.m-link:not([disabled]) {
      &:hover {
        background-color: var(@sui-pagination-item_bg__hover);
        color: var(@sui-pagination-item_color__hover)
      }
      &:focus {
        background-color: transparent;
        border-color: #DADEE0
      }
    }
  }
}
