.sui-context-help-close {
  position: absolute !important;
  right: 12px !important;
  top: 7px !important;

  width: 32px !important;
  height: 32px !important;
}

.sui-context-help-expand {
  position: absolute !important;
  right: 56px !important;
  top: 7px !important;

  width: 32px !important;
  height: 32px !important;
  font-size: 20px;

  svg {
    margin-top: 7px;
  }
}

.sui-context-content {
  overflow: auto;
}

.faq_content {
  .voting-form-bottom {
    flex-wrap: wrap;

    .f-danger {
      margin-left: 0 !important;
      margin-top: 8px;
    }
  }
}

context-help {
  md-tab-content {
    overflow: hidden;

    & > *:only-child,
    & > *:only-child > *:only-child {
      height: 100%;
    }
  }
}
