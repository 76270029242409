@import '~angular-material/modules/js/slider/slider.css'

md-slider
  user-select: none

  &[no-ticks]
    .md-track-container
      .md-track-ticks
        display: none

  .md-track-container
    .md-track
      background-color: var(--sui-slider_rail_color__base)
    .md-track.md-track-fill
      background-color: var(--sui-slider_track_background_color__base)

  .md-thumb-container
    .md-thumb
      background-color: var(--sui-global_control-background)
      &:after
        border-color: var(--sui-slider_handle_border)
    .md-focus-thumb
      background-color: var(--sui-slider_handle_background_color__hover)
    .md-focus-ring
      background-color: var(--sui-slider_handle_shadow)
    .md-sign
      background-color: var(--sui-slider_track_background_color__base)
      cursor: pointer
      .md-thumb-text
        color: var(--sui-slider-handle_text_color__base)
    .md-disabled-thumb
      background-color: var(--sui-global_control-background)
      border-color: var(--sui-slider_handle_border_color__disabled) !important

  &:not([md-vertical])
    .md-thumb-container .md-sign:after
      border-top-color: var(--sui-slider_track_background_color__base)
      border-bottom-color: var(--sui-slider_track_background_color__base)

  &[md-vertical]
    .md-thumb-container .md-sign:after
      border-right-color: var(--sui-slider_track_background_color__base)
      border-left-color: var(--sui-slider_track_background_color__base)

  &[disabled]
    .md-track-container
      .md-track
        background-color: var(--sui-slider_rail_background_color__disabled) !important
      .md-track.md-track-fill
        background-color: var(--sui-slider_track_background_color__disabled) !important
    .md-thumb-container
      .md-thumb
        background-color: var(--sui-global_control-background)
        &:after
          border-color: var(--sui-slider_handle_border_color__disabled) !important
