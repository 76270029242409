@import "../variables.less";
@import "./components/ng1-content-top/ng1-banners.component.less";
@import "./components/incomplete-account-notice/style.less";
@import (reference) '@selectel/ui-kit-ant/styles/theme/global/global.variables.less';
@import (reference) '@selectel/ui-kit-ant/styles/theme/select/select.variables.less';
@import (reference) '@selectel/ui-kit-ant/styles/theme/badge/badge.variables.less';

.server-power-toggle {
  &[disabled] {
    * {
      cursor: not-allowed !important;
    }
  }
}

.server-card {
  display: block;
}

.unread-badge {
  background: var(@sui-badge-counter_background__red);
  border-radius: 7px;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 5px;
}

md-input-container {
  textarea {
    &.desc {
      min-height: 300px !important;
      max-height: 300px;
      overflow-y: scroll;
    }

    &.new-ticket-desc {
      overflow-y: scroll;
    }

    &.m-with-grabber {
      margin-bottom: 0;
    }

    & + .md-resize-handle {
      &::after {
        display: block;
        content: ' ';
        background-image: url('../../assets/images/grabber.svg');
        height: 16px;
        width: 16px;
        position: absolute;
        right: 2px;
        top: -13px;
      }
    }
  }
}

//удалить после внесения стилей в новый ui-kit
blockquote {
  margin: 0;
  padding: 4px 16px;
  border-left: 2px solid #223a47;

  p:last-child {
    margin-bottom: 0;
  }
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

body > jdiv > jdiv > jdiv {
  z-index: 55 !important;
}

jdiv {
  #jvlabelWrap {
    z-index: 55 !important;
  }

  #jcont {
    z-index: 55 !important;
  }
}

context-help-toggle {
  position: fixed;
  z-index: 47 !important;
}

.sp-right-sidenav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 74;

  context-help {

    md-sidenav {
      background-color: var(@sui-global_background);
      // пока текущий компонент не будет заменен на компонент анта
      min-width: 480px !important;
      max-width: 480px !important;
    }
  }
}

.email-input-container {
  display: block;
}

img.make-translucent {
  opacity: 0.5;
}

.fade-animation {
  &.ng-enter,
  &.ng-leave,
  &.ng-move {
    transition: opacity 1s linear;
    opacity: 0;
  }

  &.ng-enter.ng-enter-active,
  &.ng-move.ng-move-active {
    opacity: 1;
  }
}

ticket-comment {
  ul {
    list-style-type: disc;
  }

  pre {
    min-width: 0;
    width: 100%;
    overflow-x: auto;
  }
}

context-faq {
  ul {
    list-style: disc;
    padding-left: 24px;
  }
}

.t-bullets {
  list-style: disc !important;
}

@media screen and (max-width: 1600px) {
  .md-sidenav-right {
    .smile {
      display: none;
    }

    .rate-button {
      min-width: 25px;
      padding: 0;
      text-align: center;
    }
  }
}

.mega-banner {
  .h-3 {
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .md-steppers {
    .md-stepper-indicator {
      min-height: 24px;
    }
  }

  &__icon {
    width: 80px;
    height: 80px;
  }
}

.pl-29 {
  padding-left: 29px;
}

.pool-selector-dropdown {
  .cdk-virtual-scroll-viewport {
    height: unset !important;
  }
  a {
    font-size: 14px;
  }
}

.panel-poll-select-no-text-transform {
  md-optgroup {
    label {
      text-transform: none;
      color: var(@sui-select_option_text__secondary);
      font-size: 16px;
    }
  }
}

md-icon.m-subsmall {
  margin: 0;
  width: 20px;
  height: 20px;
}
