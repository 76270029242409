md-tabs.swagger-docs-tabs {
  transition: none !important;

  md-pagination-wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }
}


swagger-docs-responses-json,
.swagger-docs-code {
  display: block;
  font-family: monospace;
  background: #25323a !important;
  min-width: 100%;
  padding: 12px;
  border-radius: 2px;
  color: hsl(0, 0%, 97%);
  width: 100%;
  overflow-x: auto;
}


swagger-docs,
swagger-docs-group,
swagger-docs-group-item {
  display: block;
}


swagger-docs-params-table,
swagger-docs-params-table-row {
  display: block;
}


swagger-docs-responses,
swagger-docs-responses-json {
  display: block;
}

swagger-docs-responses-json {
  position: relative;

  .swagger-docs-responses-json-actions {
    background: white;
    transition: 200ms;
    opacity: 0;

    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover .swagger-docs-responses-json-actions {
    opacity: 0.8;
  }
}

swagger-docs-request-row {
  display: block;
}

.swagger-docs-deprecated {
  & > .swagger-docs-path {
    text-decoration: line-through;
    color: #B5B5B5;
  }

  & > .swagger-docs-summary {
    color: #B5B5B5 !important;
  }

  & > .swagger-docs-query {
    opacity: 0.5;
  }
}
