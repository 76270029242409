@import '~angular-material/modules/js/switch/switch.css'

switch($color1, $color2, $color3)
  margin 0
  .md-thumb
    background-color var(--sui-switch_handle__unchecked_loading)
  .md-thumb:before
    z-index: -999!important
  &.md-checked .md-ink-ripple
    color: $color1
  &.md-checked .md-thumb
    background-color: $color1
  &.md-checked .md-bar
    background-color: $color2
  &.md-checked.md-focused .md-thumb:before
    background-color: $suiColor('base', 'xs') !important
  &.md-focused .md-thumb:before
    background-color: $suiColor('base', 'xs') !important
    left: -2px !important
    top: -2px !important
    right: -2px !important
    bottom: -2px !important

md-switch
  .md-container
    border: 1px solid var(--sui-switch_border__base)
    border-radius: 30px
    background: var(--sui-switch_background__base)
    min-width: 42px
    height: 24px
    line-height: 24px

  .md-thumb-container
    width: 18px
    left: 2px

  .md-thumb
    width: 18px
    height: 18px
    box-shadow: none

  &[disabled]
    .md-container
      background: var(--sui-switch_background__disabled)

    .md-thumb
      background-color: var(--sui-switch_handle__unchecked_disabled)

    &.md-checked .md-thumb
      background-color: var(--sui-switch_handle__checked_disabled)
  &
    switch(var(--sui-switch_handle__checked), $suiColor('primary', 'xxs'), black)
