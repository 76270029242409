@import '~angular-material/modules/js/progressLinear/progressLinear.css'

progress($color1, $color2)
	.md-bar
		background-color: $color1
	.md-container
		background-color: $color2

md-progress-linear.m-thin
	.md-container
	.md-container .md-bar
		height: 2px

md-progress-linear
	progress(var(--sui-progress-gray), var(--sui-progress-gray-background))
