@import '~angular-material/modules/js/tooltip/tooltip.css';

// Variables
// ----------------------------------------------------------------------------
md-tooltip, md-tooltip.md-panel
  color: var(--sui-popover_content_color__base);
  background-color: var(--sui-popover_background-color__base);
  position: absolute;

  &.tt-multiline
    height: auto;

  &.md-tooltip.md-show
    opacity: 1
