// Getter for theme colors
/**
* @deprecated use css custom properties instead
*/
$suiColor($name, $power = $KEY_DEFAULT)
  return $THEME[$KEY_COLOR][$name][$power]

// Getter for theme colors
$suiNewColor($name, $power = '1', theme = 'light')
  return $NEW_DESIGN_COLORS[theme][$name][$power]

// Getter for theme sizes
$suiSize($power = $KEY_DEFAULT)
  return $THEME[$KEY_SIZE][$power]

$suiAddFont(family, weight, style, path)
  @font-face
    font-family family
    font-weight weight
    font-style style
    src local('xxx'),
      url(path + '.ttf') format('truetype'),
      url(path + '.eot') format('eot'),
      url(path + '.woff') format('woff')

$hexToRgb(color)
  return s('%s, %s, %s', red(color), green(color), blue(color))

$suiGenerateTheme(selector, themeValues)
  $propPrefix = --color-brand
  {selector}
    for $power, $value in themeValues
      {$propPrefix}-{$power}: $value

    {$propPrefix}-{$power}-rgb: $hexToRgb(themeValues[$power])


