.mobile-confirm-input {
  width: 220px !important;
}

#brand-complete-registration {
  form {
    position: relative;
  }

  button {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .phone_w_code,
  .input-with-prefix {
    display: flex;
    flex-wrap: nowrap;

    .phone_code,
    .input-prefix {
      flex-shrink: 0;
      min-width: 40px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: -1px;
      border-radius: 6px 0 0 6px;

      md-select {
        border-radius: 6px 0 0 6px;
      }
    }

    md-input-container {
      flex-grow: 1;
    }

    .simple-select {
      md-icon {
        top: 0;
        right: 2px;
      }
    }
  }

  md-input-container {
    display: block;
  }

  .complete-registration-submit {
    display: flex;
    justify-content: space-between;
  }

  .complete_registration_sms_spinner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    background: rgba(255, 255, 255, 0.7);
    justify-content: center;
    align-items: center;
    bottom: 0;
    margin: 0 !important;
    z-index: 1000;
  }

  .registration_loading {
    width: 300px;
    height: 300px;
    position: relative;

    .complete_registration_sms_spinner {
      display: flex !important;
    }
  }
}

.suggestions-list li {
  height: auto !important;
  line-height: 1;
  padding-top: 12px;
  padding-bottom: 12px;

  .highlight {
    color: #199ad2;
  }
}

.phone_w_code,
.input-with-prefix {
  display: flex;
  flex-wrap: nowrap;

  .phone_code,
  .input-prefix {
    flex-shrink: 0;
    min-width: 40px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-right: -1px;
    border-radius: 6px 0 0 6px;

    md-select {
      border-radius: 6px 0 0 6px;
    }
  }

  .phone_code + md-input-container input {
    border-radius: 0 6px 6px 0;
  }

  md-input-container {
    flex-grow: 1;
  }

  .simple-select {
    md-icon {
      top: 0;
      right: 2px;
    }
  }
}
