@import 'button-variables.styl'

// Mixins
// ----------------------------------------------------------------------------

suiButtonSize($height, $font, $padding, $weight = 400)
  min-height: $height - ($button-border-width * 2)
  padding: $padding
  font-size: $font
  font-weight: $weight
  line-height: $height - ($button-border-width * 2)

suiLinkButton($color, $colorHover, $colorDisabled, $backgroundHover)
  background: none
  color $color
  md-icon
    color $color
  &:not([disabled]):hover
    background-color $backgroundHover
  &:not([disabled]):hover
  &:not([disabled]):hover md-icon
    color $colorHover
  &[disabled]
  &[disabled] md-icon
    color: $colorDisabled

suiCommonButton(
  $color,
  $colorDisabled,
  $colorHover,
  $background,
  $backgroundDisabled,
  $backgroundHover,
  $backgroundFocus
)
  color: $color
  md-icon
    color: $color
  &:not([disabled]):hover
    color: $colorHover
    background: $backgroundHover
  &:not([disabled]):focus
    background: $backgroundFocus

  &[disabled]
  &:not(.m-icon)[disabled] md-icon
    background: $backgroundDisabled

  &[disabled]
  &[disabled] md-icon
    color: $colorDisabled

suiSolidButton(
  $color,
  $colorDisabled,
  $colorHover,
  $colorFocus,
  $background,
  $backgroundDisabled,
  $backgroundHover,
  $backgroundFocus,
  $border,
  $borderDisabled,
  $borderHover,
  $borderFocus
)
  color: $color
  md-icon
    color: $color !important
  background: $background
  border-color: $border
  &:not([disabled]):hover
    color: $colorHover
    background: $backgroundHover
    border-color: $borderHover
  &:not([disabled]):focus
    background: $backgroundFocus
    border-color $borderFocus
    color: $colorFocus
  &[disabled]
    background: $backgroundDisabled
    border-color: $borderDisabled
    color: $colorDisabled

    md-icon
      color: $colorDisabled !important

suiBorderedButton(
  $color,
  $colorDisabled,
  $colorHover,
  $background,
  $backgroundDisabled,
  $backgroundHover,
  $border,
  $borderDisabled,
  $borderHover
)
  color: $color
  background: $background
  border-color: $border
  md-icon
    color: $color
  &:not([disabled]):hover
    background: $backgroundHover
    color: $colorHover
    border-color: $borderHover

    md-icon
      color: $colorHover !important
  &[disabled]
    color: $colorDisabled
    background: $backgroundDisabled
    border-color: $borderDisabled
  &[disabled] md-icon
    color: $colorDisabled
