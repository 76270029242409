@import '~angular-material/modules/js/backdrop/backdrop.css'
@import '~angular-material/modules/js/dialog/dialog.css'

// Variables
// ----------------------------------------------------------------------------

$dialog-radius ?= 12px
$dialog-backdrop ?= rgba(0,0,0,.5)
$dialog-background ?= var(--sui-global_control-background)
$dialog-title-size ?= $h2-font-size;
$dialog-title-height ?= 70px;
$dialog-title-weight ?= 400;


// Mixins
// ----------------------------------------------------------------------------

dialogDefault($theme)

  $suffix = '.m-' + $theme
  $selector1 = '.md-button.m-bordered'
  $selector2 = '.md-button.m-solid'

  if $theme isnt 'base'
    //$selector1 += $suffix
    $selector2 += $suffix

  md-dialog-actions
    .md-button + .md-button
      margin-left: $suiSize()
    .md-button:not(:last-child)
      @extends {$selector1}
      margin: 0
    .md-button:last-child
      @extends {$selector2}


// Default dialog styles
// ----------------------------------------------------------------------------

.md-dialog-container
  display: block
  text-align: center
  overflow-y: scroll
  background: none

  &:before
  & > .md-dialog-focus-trap
    content: ''
    display: inline-block
    height: 100%
    vertical-align: middle

md-dialog
  display: inline-block
  min-width: 480px
  margin: $suiSize('xl') 0
  padding: $suiSize('xl')
  text-align: left
  vertical-align: middle
  max-height: none !important
  background: $dialog-background
  border-radius: $dialog-radius
  border: 1px solid var(--sui-modal-border-color__base)
  color: var(--sui-modal-text-color__base)
  box-shadow: 0 0 2px 0 rgba(9, 36, 51, 12%), 0 8px 16px rgba(0, 0, 0, 12%)

  &.md-transition-in
  &.md-transition-out
    transition .25s

  .md-dialog-content
    padding: 0
    overflow: hidden

  .md-title
    margin: 0
    //height: $dialog-title-height
    font-size: $dialog-title-size
    font-weight: $dialog-title-weight
    //line-height: $dialog-title-height

  .md-dialog-content-body
    padding: $suiSize('l') 0px

  md-dialog-actions
    overflow: visible
    padding: $suiSize('l') 0px 0px
    min-height: auto

    .md-button
      margin: 0

    .md-button + .md-button
      margin-left: $suiSize()

  &.m-s
    width: 480px

  &.m-m
    width: 600px

  &.m-l
    width: 760px

md-backdrop
  background: rgba(0,0,0,0)
  &.md-opaque
    background: rgba(0,0,0,.6)

// Color modificators
// Used by `css` options for new dialog
// ----------------------------------------------------------------------------


md-dialog
  dialogDefault('base')

// Add custom behavior for nested dialog windows
// Every non last dialog in stack will get full dark background
// and resized aka `.md-dialog-transition-out`
//.md-dialog-backdrop:last-of-type:not(:only-of-type)
//	&.md-opaque
//	&.md-opaque.ng-leave
//	&.md-opaque.ng-enter.ng-enter-active
//		opacity: 1
//		background: rgba(0,0,0,1)

.md-dialog-container[aria-hidden="true"]
  opacity: 0
  transform: scale(0.2)
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)
