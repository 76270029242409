@import '~angular-material/modules/js/menu/menu.css';

// Variables
// ----------------------------------------------------------------------------

// Default menu styles
// ----------------------------------------------------------------------------
.md-menu
  padding 0

md-menu-content
  padding: 0 0
  border-radius: $radius-base
  border: 0
  background: var(--sui-global_control-background)
  background-clip: padding-box
  box-shadow: 0 8px 16px var(--sui-shadow_color), 0 0 2px var(--sui-shadow_color__additional)

md-menu-item
  height: auto
  min-height: auto
  color: var(--sui-dropdown-menu-item-color)

  .md-button
    border-radius: 0

    &:not([disabled]):focus
      box-shadow: none
      background: var(--sui-button_text-background__focus)
      border-radius: 0
    md-icon
      margin-right: 0

.md-open-menu-container md-menu-divider
  height: 1px
  margin-top: 0
  margin-bottom: 0
  background: var(--sui-dropdown-menu-item-divider_background)

.md-open-menu-container
  transition none !important
  transition-duration 0s !important
  overflow: visible
  *
    transition none !important
    transition-duration 0s !important
