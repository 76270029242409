@import '~angular-material/modules/js/input/input.css';

suiNumberContainer()
  .sui-number-container
    border: 1px solid var(--sui-number-border)
    border-radius: $radius-base
    transition: all 150ms ease

    .md-errors-spacer {
      display: none
    }

    &:not([disabled]):hover,
    &.sui-number-container-focused:not([disabled])
      border-color: var(--sui-number-border__hover)

    &.sui-number-container-focused:not([disabled])
      box-shadow: 0 0 0 4px var(--sui-number-outline)

    // Size of +/- icons
    .md-button.m-icon
      min-width: 36px !important;
      height: $number-inner-height;
      border: 0 !important;

      &:hover {
        background-color: var(--sui-number-handler-bg__hover)
      }


    // Drop right border and radius in first button
    .md-button.m-bordered:first-of-type
      order: 1;
      border-top-right-radius 0
      border-bottom-right-radius 0


    // Drop left border radius in last button
    .md-button.m-bordered:last-of-type
      order: 3;
      border-top-left-radius 0
      border-bottom-left-radius 0

    // Disabled buttons
    .md-button.m-bordered[disabled]
      background-color: var(--sui-number-background__disabled)
      color: var(--sui-number-text__disabled)

      md-icon
        background-color: var(--sui-number-background__disabled)

    // Central number input
    input
      order: 2;
      height: $number-inner-height !important;
      border-radius: 0;
      border: 0 !important
      text-align: center;
      box-shadow: none !important

      &[type='number'],
      &[type='number']::-webkit-outer-spin-button,
      &[type='number']::-webkit-inner-spin-button
        -webkit-appearance: none;
        -moz-appearance: textfield;

      &[disabled]
        margin-bottom: 0;
        background-color: var(--sui-number-background__disabled)
        color: var(--sui-number-text__disabled)

    &[disabled]
      cursor: not-allowed
      background-color: var(--sui-number-background__disabled)

      & > .md-button, & > input, & > .md-button md-icon
        cursor: not-allowed
        background-color: transparent
        color: var(--sui-number-text__disabled) !important

        &:hover
          color: var(--sui-number-text__disabled) !important
          background-color: transparent !important

      & > .md-button:hover md-icon
        color: var(--sui-number-text__disabled) !important

  &.ng-invalid .sui-number-container,
  md-input-container.md-input-invalid & .sui-number-container
    border-color: var(--sui-number-border__error)

    &:not([disabled]):hover,
    &.sui-number-container-focused:not([disabled])
      border-color: var(--sui-number-border__error-hover) !important

$number-inner-height = 34px;
sui-number, sui-unit-number
  display: block;

  suiNumberContainer()
