@import (reference) "@selectel/ui-kit-ant/styles/variables/classes.less";
@import (reference) "@selectel/ui-kit-ant/layout/layout.module.less";
@import (reference) 'packages/panel-kit/src/lib/modules/layout/components/header/header.component.less';

@import "mobile-menu/variables.less";
@import "mobile-menu/light.theme.less";
@import "mobile-menu/dark.theme.less";

@sui-mobile-menu_drawer-translate-y__mobile: @panel-header-height__mobile + 1px;
@sui-mobile-menu_drawer-translate-y__tablet: @panel-header-height__tablet + 1px;

.sui-mobile-menu-shared() {
  .menu-item,
  .menu-back {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
  }

  .menu-link {
    color: var(@sui-mobile-menu_item-color_base);
  }

  .menu-item {
    justify-content: space-between;
    color: var(@sui-mobile-menu_item-color_base);
    background-color: var(@sui-mobile-menu_item-background_base);
    border-bottom: 1px solid var(@sui-mobile-menu_item-border_base);

    .anticon {
      font-size: 16px;
    }

    .menu-item-label {
      display: inline-flex;
      align-items: center;
    }

    &:visited {
      color: var(@sui-mobile-menu_item-color_base);
    }

    &:hover {
      color: var(@sui-mobile-menu_item-color_base);
    }
  }

  .menu-back {
    justify-content: space-between;
    color: var(@sui-mobile-menu_back-color_base);
    background-color: var(@sui-mobile-menu_back-background_base);
    border-bottom: 1px solid var(@sui-mobile-menu_back-border_base);

    &:hover {
      color: var(@sui-mobile-menu_back-color_base);
    }

    &:after {
      content: '';
    }


    .back-icon {
      transform: rotate(180deg);
    }

    .ant-badge {
      .back-icon {
        margin-right: inherit;
        font-size: inherit;
        width: initial;
        height: inherit;
        color: inherit;
      }
    }
  }

  .remove-br {
    br {
      display: none;
    }
  }
}

.@{green-light} .sui-mobile-menu_drawer {
  .sui-mobile-menu-light();
}

.@{dark-theme-name} .sui-mobile-menu_drawer {
  .sui-mobile-menu-dark();
}

.@{green-dark} .sui-mobile-menu_drawer {
  .sui-mobile-menu-dark();
}

.ant-drawer {
  --sui-mobile-menu_drawer-translate-x: 100%;
  --sui-mobile-menu_drawer-translate-y: @sui-mobile-menu_drawer-translate-y__mobile;

  &-left {
    --sui-mobile-menu_drawer-translate-x: -100%;
  }

  @media (min-width: @sui-tablet-min) and (max-width: @sui-tablet-max)  {
    --sui-mobile-menu_drawer-translate-y: @sui-mobile-menu_drawer-translate-y__tablet;
  }

  &.ant-drawer-open {
    .sui-mobile-menu_drawer {
      transform: translate(0%, var(--sui-mobile-menu_drawer-translate-y)) !important;
    }
  }

  &:not(.ant-drawer-open) {
    .sui-mobile-menu_drawer {
      transform: translate(var(--sui-mobile-menu_drawer-translate-x), var(--sui-mobile-menu_drawer-translate-y)) !important;
    }
  }
}

.sui-mobile-menu_drawer {
  .sui-mobile-menu-light();
  box-shadow: none !important;
  border-right: 1px solid var(@sui-mobile-menu_back-border_base);

  &__right {
    border-right: none;
    border-left: 1px solid var(@sui-mobile-menu_back-border_base);
  }

  .ant-drawer-wrapper-body {
    height: calc(100% - 48px) !important;

    .ant-drawer-body {
      padding: 0;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */

      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }


  .sui-mobile-menu-shared();
}
