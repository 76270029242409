@symbol-height: 16px;
@symbol-width: 8px;

.theme(
  @default-color: black,
  @string-color: green,
  @number-color: blue,
  @boolean-color: red,
  @null-color: #855A00,
  @undefined-color: rgb(202, 11, 105),
  @function-color: #FF20ED,
  @rotate-time: 100ms,
  @toggler-opacity: 0.6,
  @toggler-color: #45376F,
  @bracket-color: blue,
  @key-color: #00008B,
  @url-color: blue) {

  font-family: IBM Plex Mono, monospace;
  &, a, a:hover {
    color: @default-color;
    text-decoration: none;
  }
  .json-formatter-row {
    position: static;
    border-left: none;
    margin-left: @symbol-width * 2;
    padding-left: 0;
  }
  .json-formatter-children {
    &.json-formatter-empty {
      opacity: 0.5;
      margin-left: 1rem;

      &:after {
        display: none;
      }

      &.json-formatter-object:after {
        content: "No properties";
      }

      &.json-formatter-array:after {
        content: "[]";
      }
    }
  }
  .json-formatter-string, .json-formatter-stringifiable {
    color: @string-color;
    white-space: pre;
    word-wrap: break-word;
  }
  .json-formatter-number {
    color: @number-color;
  }
  .json-formatter-boolean {
    color: @boolean-color;
  }
  .json-formatter-null {
    color: @null-color;
  }
  .json-formatter-undefined {
    color: @undefined-color;
  }
  .json-formatter-function {
    color: @function-color;
  }
  .json-formatter-date {
    background-color: fade(@default-color, 5%);
  }
  .json-formatter-url {
    text-decoration: underline;
    color: @url-color;
    cursor: pointer;
  }
  .json-formatter-bracket {
    color: @bracket-color;
  }
  .json-formatter-key {
    color: @key-color;
    padding-right: @symbol-width;
  }
  .json-formatter-toggler-link {
    cursor: pointer;
  }
  .json-formatter-toggler {
    position: absolute;
    left: -(@symbol-width * 2 - @symbol-width / 2);

    line-height: @symbol-height;
    font-size: @symbol-height * 0.8;
    vertical-align: middle;
    opacity: @toggler-opacity;
    cursor: pointer;
    padding-right: 0;

    height: @symbol-width;
    width: @symbol-width;

    &:after {
      display: inline-block;
      transition: transform @rotate-time ease-in;
      content: "+";
    }
  }
  > a > .json-formatter-preview-text {
    opacity: 0;
    transition: opacity 0.15s ease-in;
    font-style: italic;
    white-space: nowrap;
    padding-left: @symbol-width;
  }
  &:hover > a > .json-formatter-preview-text {
    opacity: 0.6;
  }
  &.json-formatter-open {
    > .json-formatter-toggler-link .json-formatter-toggler:after {
      content: "-";
      transform: rotate(0);
    }

    > .json-formatter-children:after {
      display: inline-block;
    }

    > a > .json-formatter-preview-text {
      display: none;
    }

    &.json-formatter-empty:after {
      display: block;
    }
  }
}

.json-formatter-brand.json-formatter-row {
  position: relative;
  font-size: @symbol-height;
  margin-left: @symbol-width * 2;
  padding-left: @symbol-width;

  // border-left: 1px solid #737a7e;
  .theme(#F5F7F8, #FF9C6E, #b5cea8, #9cdcfe, #9cdcfe, #9cdcfe, #F5F7F8, 100ms, 0.6, #3d3e3d, #F5F7F8, #69C0FF, #FF9C6E);
}
