@import (reference) '@selectel/ui-kit-ant/styles/variables/colors.less';
@import (reference) '@selectel/ui-kit-ant/styles/theme/global/global.variables.less';

ticket-create-form {
  display: block;
}
.ticket-form__time {
  max-width: 70px;
}
.ticket-list-header {
  min-height: 20px;
}

ticket-create-form panel-sui-notice-page-item {
  display: block;
  @media (max-width: @sui-mobile-max) {
    display: none;
  }
}

.reset-ticket-button {
  width: 20%;
}

.submit-ticket-button {
  width: 30%;
}

@media (max-width: @sui-mobile-max) {
  .reset-ticket-button,
  .submit-ticket-button {
    width: 100%;
  }
}

.is-context-ticket {
  .reset-ticket-button,
  .submit-ticket-button {
    width: 100%;
  }
}
