@import (reference) "@selectel/ui-kit-ant/styles/index.less";

panel-ng1-banners {
  display: flex;
  flex-direction: column;

  .ng1-content-top-container {
    margin-bottom: 24px;

    @media (max-width: @sui-mobile-max) {
      margin-bottom: 16px;
    }
  }

  cookies-notice {
    display: block;
  }

  .mega-banner {
    z-index: 1;
  }
}
