@import 'modules/referral/style.less';
@import 'modules/selectel-registration/style.less';
@import 'modules/common/components/page-loader/page-loader.less';
@import 'modules/tickets/styles.less';
@import 'modules/tickets-ng2/styles.less';
@import 'modules/tsa/style.less';
@import 'modules/profile/index.less';
@import 'modules/profile/components/api-keys/style.less';

@BRAND_1: #7247e8;

// TODO: delete s-underline-brand-6
@BRAND_6: #DE1212;

.s-underline(@color) {
  background: @color;
}

.s-underline-brand-6 {
  &:before {
    .s-underline(@BRAND_6);
  }
}

.s-underline-brand-6 {
  position: relative;
  border-radius: 2px !important;
  border: 1px solid #e8e8e8 !important;
  padding-bottom: 18px !important;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 0 0 1px 1px;
  }
}

.no-br-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.no-br-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-border {
  border: none !important;
}

.no-pointer {
  cursor: default !important;
}


.balance-state {
  padding-top: 2px;
}

.subtitle-bl {
  font-size: 13px;
  line-height: 15px;
  color: #696969;

  p {
    font-size: 15px;
    line-height: 18px;
    color: black;
    padding-top: 4px;
  }

  .h4 {
    height: 36px;
  }

  .subtitle-bl-info {
    padding-top: 16px !important;
    min-height: 114px;
  }
}

.txt-ovf-ell {
  overflow: hidden;
  text-overflow: ellipsis;
}


.width-100 {
  width: 100%;
}
.billing-services_description {
  min-height: 36px;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
}

// workaround for md-tooltip in scrollable content
body > .md-panel-outer-wrapper {
  position: fixed;
}

@media (max-width: @screen-sm-max) {
  .billing-services_description {
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
  .billing-services_description_button {
    margin-top: 12px;
    width: 100%;
    height: 40px;
    font-size: 16px !important;
    font-weight: 400;
  }
}
