novnc-console {
  display: block;
  position: relative;
  min-height: 489px;
  background: black;

  &:before {
    display: block;
    position: absolute;
    text-align: center;
    margin-top: 10px;
    width: 100%;
    color: white;
  }
  &.connecting:before {
    content: "Connecting...";
  }
  &.error-too-many-connection-attempts:before {
    content: "Disconnecting...";
  }
  &.error-too-many-connection-attempts:before {
    content: "Too many connection attempts";
  }

  canvas {
    border: 1px solid transparent;
  }
  &:full-screen {
    position: static;
  }
  &:full-screen canvas {
    max-width: none;
    border-width: 0;
    cursor: none !important;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
