@import (reference) '@selectel/ui-kit-ant/styles/index.less';
@import "../../../modules/layout/components/mobile-menu-shared.less";
@import "variables.less";
@import "light.theme.less";
@import "dark.theme.less";
@import "green-light.less";
@import "green-dark.theme.less";

@sp-menu-width: 200px;

:root {
  .sui-submenu-light();
  .sui-mobile-menu-light();
}

.sui-green-light-theme {
  .sui-submenu-green-light();
  .sui-mobile-menu-light();
}

.sui-dark-theme {
  .sui-submenu-dark();
  .sui-mobile-menu-dark();
}

.sui-green-dark-theme {
  .sui-submenu-green-dark();
  .sui-mobile-menu-dark();
}
.panel-submenu() {
  .sp-submenu {
    background: var(@sui-global_background);
    border-right: 1px solid var(@sui-submenu-border_color);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    width: @sp-menu-width;
    height: 100%;
    z-index: 3;

    /* Hide scrollbar for Chrome, Safari and Opera */

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */

    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .project-title-switcher {
      .project-title {
        height:57px;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
    }

    &-group {
      display: flex;
      flex-direction: column;

      padding: 8px 0;

      &:first-child {
        padding-top: 0;
      }

      &:not(:first-child) {
        border-top: 1px solid var(@sui-submenu-border_color);
      }
    }

    &-link {
      color: var(--sui-submenu-item_color__base);
      font-size: 14px;
      line-height: 20px;
      transition: color .3s ease;
      padding: 8px 0 8px 10px;

      &:visited {
        color: var(--sui-submenu-item_color__base);
      }

      &:hover, &:visited:hover {
        color: var(--sui-submenu-item_color__hover);
      }

      // workaround for typography focus override
      &:focus:not(:focus-visible) {
        color: var(--sui-submenu-item_color__hover);
      }

      &.is-active {
        color: var(--sui-submenu-item_color__active);
      }

      &-content {
        display: inline-flex;
        align-items: flex-end;
        justify-content: space-between;

        nz-badge {
          display: inline-flex;
        }
      }
    }
  }
}

.panel-submenu-mobile() {
  @media (max-width: @sui-mobile-max) {
    .sp-submenu {
      border-right: none;
      width: 100%;
      overflow-y: unset;
      overflow-x: unset;

      .project-title-switcher {
        border-bottom: 1px solid var(@sui-submenu-border_color__mobile);
      }

      &-body {
        margin: 0;
      }

      &-group {
        padding: 0;

        &:first-child {
          padding-top: 0;
        }

        &:not(:first-child) {
          border-top: none;
        }

        border-top: none;
      }

      &-link {
        font-size: 16px;
        line-height: 24px;
        border-bottom: 1px solid var(@sui-submenu-border_color__mobile);
        padding: 16px;

        &-content {
          &-text {
            br {
              display: none;
            }
          }
        }
      }
    }
  }
}
