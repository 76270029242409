*
*:after
*:before
	box-sizing: border-box

html
	height: 100%

body
	height: 100%
	background: #fff

img
	display: inline-block
	line-height: 1
	vertical-align: baseline

:focus
	outline none

a
button
[ui-sref]
[ng-click]
	cursor pointer

p
	margin: 0

pre
	display: inline-block
	min-width: 600px
	margin: 0
	padding: 5px 10px
	border: 1px solid $code-bd
	border-radius: 3px
	background: $code-bg
	color: $code-color
