sui-flag
  md-icon
    overflow: hidden;
    width: 20px;
    height: auto;

    max-width: none;
    max-height: none;
    min-width: 0;
    min-height: 0;

  &.m-xl md-icon
    width: 36px;
    height: auto;

  &.m-l md-icon,
  &.m-large md-icon
    width: 28px;
    height: auto;

  &.m-s md-icon,
  &.m-small md-icon
    width: 16px;
    height: auto;

  &.m-xs md-icon
    width: 10px;
    height: auto;
