$BRAND_COLORS = {
  "xxs"    : hsl(358, 81%, 95%),
  "xs"     : hsl(358, 81%, 84%),
  "s"      : hsl(358, 81%, 68%),
  "default": hsl(201, 70%, 12%),
  "l"      : hsl(358, 81%, 37%),
  "xl"     : hsl(358, 81%, 30%),
  "xxl"    : hsl(358, 81%, 20%)
}

$BRAND_COLORS_DARK_THEME = {}

for $power, $color in $BRAND_COLORS
  $BRAND_COLORS_DARK_THEME[$power] = invertedColor($light-theme-bg, $dark-theme-bg, $color)

$suiGenerateTheme(':root', $BRAND_COLORS);
$suiGenerateTheme('body.sui-dark-theme, body.sui-green-dark-theme', $BRAND_COLORS_DARK_THEME);
