@import '~angular-material/modules/js/button/button.css'
@import 'button-variables.styl'
@import 'button-mixins.styl'



// Default button styles
// ----------------------------------------------------------------------------

.md-button
  margin: 0
  min-width: $button-min-width
  box-shadow: none
  border-style: solid
  border-color: transparent
  border-width: $button-border-width
  border-radius: $button-border-radius
  text-transform: none

  &:focus:not(:focus-visible)
  &:focus-visible
    border-radius: $button-border-radius // fix for focus visible of links in ui-kit-ant

  &[disabled]
    cursor: not-allowed
    color: var(--sui-button_primary-color__disable) !important

.sui-control-set
  display: flex
  md-input-container
    vertical-align: top

  // Drop radius in first
  .md-button:first-child
  md-input-container:first-child input
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0 !important

  // Drop radius in last
  .md-button:last-child
  md-input-container:last-child input
    border-top-left-radius: 0 !important
    border-bottom-left-radius: 0 !important

  // Drop radius in all non-edge controls
  .md-button + .md-button:not(:last-child)
  .md-button + md-input-container:not(:last-child) input
    border-radius: 0

  // Drop right border in all sibling buttons
  .md-button:not(:last-child)
    border-right-width: 0

  // Drop left border in all buttons after inputs
  md-input-container + .md-button
    border-left-width: 0

  // This is need for custom switchers in my-selectel-panel
  .md-button.m-bordered[ng-class*="m-brand"]
    &:not([disabled]):not(.m-brand):not(:hover)
      border-color: $suiNewColor('data-blue', '4');

      .sui-dark-theme, .sui-green-dark-theme &
        border-color: $suiNewColor('data-blue', '4', 'dark');

    &.m-brand:not([disabled]) + .md-button
      border-left-color: var(--sui-button_secondary-border__base) !important;
      &:hover:not([disabled])
        border-left-color: var(--sui-button_secondary-border__hover) !important;
    &:not([disabled]):hover + .md-button
      border-left-color: var(--sui-button_secondary-border__hover) !important

  .md-button
    &:hover:not([disabled])
      & + .md-button
        border-left-color: var(--sui-button_secondary-border__hover);
    &[disabled]
      background-color var(--sui-button_secondary-background__disable)
      *
        color var(--sui-button_secondary-color__disable)

  .md-input, input
    &[disabled]
      background-color var(--sui-input_background-color__disabled)
      color var(--sui-input_color__disabled) !important

// Buttons common
// ----------------------------------------------------------------------------

.md-button
  &
  md-menu-item > &
    suiCommonButton(
      var(--sui-button_text-color__base),
      var(--sui-button_text-color__disable),
      var(--sui-button_text-color__hover),
      var(--sui-button_text-background__base),
      var(--sui-button_text-background__disable),
      var(--sui-button_text-background__hover),
      var(--sui-button_text-background__focus)
    )

// Buttons-links
// ----------------------------------------------------------------------------
.md-button.m-link
  &
    suiLinkButton(
      var(--sui-button_text-color__base),
      var(--sui-button_text-color__hover),
      var(--sui-button_text-color__disabled),
      var(--sui-button_text-background__hover)
    )

// Solid buttons
// ----------------------------------------------------------------------------

.md-button.m-solid
  &
    suiSolidButton(
      var(--sui-button_primary-color__base),
      var(--sui-button_primary-color__disable),
      var(--sui-button_primary-color__hover),
      var(--sui-button_primary-color__focus),
      var(--sui-button_primary-background__base),
      var(--sui-button_primary-background__disable),
      var(--sui-button_primary-background__hover),
      var(--sui-button_primary-background__focus),
      var(--sui-button_primary-border__base),
      var(--sui-button_primary-border__disable),
      var(--sui-button_primary-border__hover),
      var(--sui-button_primary-border__focus)
    )

// Bordered buttons
// ----------------------------------------------------------------------------

.md-button.m-bordered
  &
    suiBorderedButton(
      var(--sui-button_secondary-color__base),
      var(--sui-button_secondary-color__disable),
      var(--sui-button_secondary-color__hover),
      var(--sui-button_secondary-background__base),
      var(--sui-button_secondary-background__disable),
      var(--sui-button_secondary-background__hover),
      var(--sui-button_secondary-border__base),
      var(--sui-button_secondary-border__disable),
      var(--sui-button_secondary-border__hover)
    )
  &.m-base
    background-color: var(--sui-global_control-background)

// Dashed buttons
// ----------------------------------------------------------------------------

.md-button.m-dashed
  &
    suiBorderedButton(
      var(--sui-button_secondary-color__base),
      var(--sui-button_secondary-color__disable),
      var(--sui-button_secondary-color__hover),
      var(--sui-button_secondary-background__base),
      var(--sui-button_secondary-background__disable),
      var(--sui-button_secondary-background__hover),
      var(--sui-button_secondary-border__base),
      var(--sui-button_secondary-border__disable),
      var(--sui-button_secondary-border__hover)
    )

// Buttons sizes
// ----------------------------------------------------------------------------

.md-button
  &
  md-menu-item > &
    suiButtonSize($button-base-height, $button-base-font, $button-base-padding, $button-base-weight)
    md-icon
      width: 16px
      height: 16px
      margin-top: -3px
      margin-left: -4px

      & + span
        margin-left: 4px

  md-menu-item > &
    border-radius: 0

  &.m-s
    suiButtonSize($button-small-height, $button-small-font, $button-small-padding, $button-small-weight)
    md-icon
      width: 12px
      height: 12px
      margin-top: -2px
      margin-left: 0
      & + span
        margin-left: 4px
  &.m-l
    suiButtonSize($button-large-height, $button-large-font, $button-large-padding, $button-large-weight)
    md-icon
      width: 24px
      height: 24px
      margin-top: -3px
      margin-left: -4px

// Buttons squares
// ----------------------------------------------------------------------------

.md-button.m-icon
  padding: 0 !important
  min-width: 0 !important
  width: $button-base-height
  height: $button-base-height

  md-icon
    margin-left: 0 !important
    margin-right: 0 !important
    width: 24px
    height: 24px

  &.m-s
    width: $button-small-height
    height: $button-small-height

    md-icon
      width: 12px
      height: 12px

  &.m-l
    width: $button-large-height
    height: $button-large-height

    md-icon
      width: 32px
      height: 32px

.sui-combined-container
  height: $button-base-height

  &.m-s
    height: $button-small-height

  &.m-l
    height: $button-large-height
