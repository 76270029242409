@import (reference) '@selectel/ui-kit-ant/styles/variables/classes.less';
@import (reference) '@selectel/ui-kit-ant/styles/variables/colors.less';

:root {
  --sui-area-chart-grid_color:  @sui-color[@light][@data-blue][@5];
  --sui-are-chart-axis_color: @sui-color[@neutral][@gray][@13];
}

.@{dark-theme-name} {
  --sui-area-chart-grid_color:  @sui-color[@dark][@data-blue][@5];
  --sui-are-chart-axis_color: @sui-color[@neutral][@gray][@2];
}

.@{green-dark} {
  --sui-area-chart-grid_color:  @sui-color[@dark][@data-blue][@5];
  --sui-are-chart-axis_color: @sui-color[@neutral][@gray][@2];
}

