@import (reference) '@selectel/ui-kit-ant/styles/variables/colors.less';
@import (reference) '@selectel/ui-kit-ant/styles/theme/global/global.variables.less';
@import (reference) 'variables.less';

.sui-mobile-menu-dark() {
    @{sui-mobile-menu_item-background_base}: var(@sui-global_background);
    @{sui-mobile-menu_item-color_base}: @sui-color[@dark][@data-blue][@10];
    @{sui-mobile-menu_item-border_base}: @sui-color[@dark][@data-blue][@3];

    @{sui-mobile-menu_back-background_base}: @sui-color[@dark][@data-blue][@1];
    @{sui-mobile-menu_back-color_base}: @sui-color[@dark][@data-blue][@10];
    @{sui-mobile-menu_back-border_base}: @sui-color[@dark][@data-blue][@3];
}
