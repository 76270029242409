@import '~angular-material/modules/js/sidenav/sidenav.css'

md-sidenav
	background-color: white;

	@media(min-width: 1600px)
		width: 100%;
		max-width: 600px;

	@media(max-width: 456px)
		width: calc(100% - 60px);
		min-width: calc(100% - 60px);
		max-width: calc(100% - 60px);
