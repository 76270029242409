@import (reference) '@selectel/ui-kit-ant/styles/index.less';

.sui-submenu-light() {
    @{sui-submenu_background}: @sui-color[@neutral][@gray][@1];
    @{sui-submenu-border_color}: @sui-color[@light][@data-blue][@3];
    @{sui-submenu-border_color__mobile}: @sui-color[@light][@data-blue][@2];

    @{sui-submenu-item_color__base}: @sui-color[@light][@data-blue][@9];
    @{sui-submenu-item_color__hover}: @sui-color[@light][@signal-red][@6];
    @{sui-submenu-item_color__active}: @sui-color[@light][@signal-red][@6];

    @{sui-submenu-item-border__focus}: @sui-color[@light][@data-blue][@3];
}
