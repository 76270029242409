@import '../number/number.styl'

// HACK
.md-icon-left, .md-icon-right
  padding 0 !important

sui-number2
  display: block

  suiNumberContainer()

  input
    &.deny-manual[disabled]
      background-color var(--sui-number-background__disabled) !important
      cursor default !important
      color: var(--sui-number-text__disabled) !important

  &.ng-valid input[disabled].deny-manual
    border-color rgb(209, 209, 209)


  button.md-button.m-icon
    margin 0 !important
    min-width 36px !important

  button
    &[disabled]
      background-color var(--sui-number-background__disabled) !important

      *
        color var(--sui-number-text__disabled) !important

    &.md-button:first-of-type
      border-right-width 0
      border-bottom-right-radius 0
      border-top-right-radius 0

    &.md-button:last-of-type
      border-left-width 0
      border-bottom-left-radius 0
      border-top-left-radius 0


md-input-container
  margin: 0
  padding: 0

  .md-errors-spacer
    min-height: 0
