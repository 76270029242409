@import '~angular-material/modules/js/icon/icon.css';

md-icon
  color $text-color-base


md-icon
  width: 20px
  height: 20px
  min-width: 12px
  min-height: 12px
md-icon.m-small
  width: 16px
  height: 16px
md-icon.m-large
  width: 28px
  height: 28px

md-icon
  &[md-svg-src="chevron-up"],
  &[md-svg-src="chevron-down"],
  &[md-svg-icon="chevron-up"],
  &[md-svg-icon="chevron-down"]
    color: $suiNewColor('data-blue', '7')
