@import '~angular-material/modules/js/checkbox/checkbox.css'


// Default radioButton styles
// ----------------------------------------------------------------------------
md-checkbox
  margin-bottom: 0
  min-height: 20px

  .md-label
    margin-left: 28px
    vertical-align: baseline

  .md-container
    top: 0
    transform: none

  .md-icon
    border-width: 1px
    border-style: solid
    border-radius: 4px
    background: var(--sui-checkbox_background__unchecked)
    border-color: var(--sui-checkbox_border__unchecked)
    transition: box-shadow 0.2s ease-in-out

  &.md-checked .md-icon
    background: var(--sui-checkbox_background__checked)
    border-color: var(--sui-checkbox_border__checked)

    &:after
      left: 5.8px
      top: 1.4px
      border-color: var(--sui-checkbox_check__checked);
      border-radius: 0 0 2px 0;
      transform: rotate(45deg) translateZ(1px)

  &[disabled]
    cursor not-allowed
    .md-icon
      background-color var(--sui-checkbox_background__disabled)

  &:not([disabled])
    &:hover .md-icon
      border-color: var(--sui-checkbox_border__hover)

    &.ng-invalid.ng-dirty .md-icon
      border-color: var(--sui-checkbox_border__error)

    &.md-focused
      .md-icon
        box-shadow: 0 0 0 4px var(--sui-checkbox_outline__base)

      .md-container:before
        background-color: transparent !important

.ng-submitted
  md-checkbox:not([disabled]).ng-invalid.ng-dirty .md-icon
    border-color: var(--sui-checkbox_border__error)
