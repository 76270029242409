@import 'md-stepper'

$stepper-button-background ?= var(--sui-global_background)
$stepper-button-background-hover ?= var(--sui-global_background)

$stepper-button-padding-lr ?= 16px
$stepper-button-content-padding-lr ?= 8px

$stepper-circle-radius ?= 12px

// override circle size
.md-steppers
  .md-stepper-number
    width: $stepper-circle-radius * 2
    height: $stepper-circle-radius * 2
    font-size: $stepper-circle-radius
    border-radius: $stepper-circle-radius
    line-height: $stepper-circle-radius * 2 - 2px

  .md-stepper-icon
    width: $stepper-circle-radius * 1.5
    height: $stepper-circle-radius * 1.5
    font-size: $stepper-circle-radius * 1.5

// override colors
// --------------------------------
// button background
buttonBackground(bg)
  background-color: bg
  .md-stepper-indicator-wrapper
    background-color: bg
  .md-stepper-number
    &:before
    &:after
      background-color: bg

.md-steppers
  .md-stepper-indicator
    // button
    buttonBackground($stepper-button-background)
    cursor: default

    &.md-hoverable:not(.md-active):hover
      buttonBackground($stepper-button-background-hover)
      cursor: pointer

.md-steppers:not(.md-steppers-linear)
  .md-stepper-indicator:not(.md-active):hover
    buttonBackground($stepper-button-background-hover)
    cursor: pointer


// colorize
colorState(active, active-hover, completed, completed-hover, inactive, inactive-hover, block)
  .md-stepper-indicator
    block(inactive)
    &.md-completed
      block(completed, var(--sui-steps_icon-color__process), var(--sui-steps_icon-color__process))
    &.md-active
      block(active, var(--sui-steps_not-custom-icon-color__process), var(--sui-steps_icon-color__process))
    &.md-hoverable.md-completed:not(.md-active):hover
      block(completed-hover)
    &.md-hoverable:not(.md-active):hover
      block(inactive-hover)
  &:not(.md-steppers-linear)
    .md-stepper-indicator
      &:not(.md-active):hover
        block(inactive-hover)

numberBackground(active, active-hover, completed, completed-hover, inactive, inactive-hover)
  block(bgcolor, tcolor = false, bdcolor = false)
    .md-stepper-number
      if tcolor
        color: tcolor
      if bdcolor
        border-color: bdcolor
      background-color: bgcolor
  colorState(active, active-hover, completed, completed-hover, inactive, inactive-hover, block)

titleColor(active, active-hover, completed, completed-hover, inactive, inactive-hover)
  block(color)
    .md-stepper-title
      color: color
  colorState(active, active-hover, completed, completed-hover, inactive, inactive-hover, block)

mClassColor()
  numberBackground(
    var(--sui-steps_icon-color__process),
    var(--sui-steps_icon-color__process),
    transparent,
    transparent,
    transparent,
    transparent
)
  titleColor(
    var(--sui-steps_title-color__process),
    var(--sui-steps_title-color__process),
    var(--sui-steps_title-color__finish),
    var(--sui-steps_title-color__finish),
    var(--sui-steps_title-color__wait),
    var(--sui-steps_title-color__wait)
  )

.md-steppers
  md-stepper &
    mClassColor()



// override margins and paddings
// --------------------------------
.md-steppers
  .md-stepper-indicator
    padding-right: $stepper-button-padding-lr !important
    padding-left: 0 !important

    .md-stepper-indicator-wrapper
      padding-left: 0 !important
      padding-right: $stepper-button-content-padding-lr

      .md-stepper-number
        margin-left: $stepper-button-content-padding-lr
        margin-right: $stepper-button-content-padding-lr

    // edge buttons
    &:first-child
      margin-left: -($stepper-button-padding-lr)
      .md-stepper-indicator-wrapper
        .md-stepper-number
          margin-left: 0 !important

    &:last-child
      margin-right: -($stepper-button-padding-lr)
      .md-stepper-indicator-wrapper
        padding-right: 0 !important

    // line
    &:after
      width: 100%

    &:last-child:after
      width: $stepper-button-padding-lr

    &:first-child:after
      left: auto
      right: -($stepper-button-padding-lr)


  .md-stepper-indicator:first-child
    padding-left: $stepper-button-padding-lr !important;

  // alternative stepper
  &.md-steppers-alternative
    .md-stepper-indicator:last-child:after
      width: 50%

.md-steppers .md-stepper-indicator.md-completed:after
  background-color: var(--sui-steps_tail-color__finish)

.md-steppers-vertical
  .md-stepper
    padding-left: 0
    padding-right: 0
    padding-top: $stepper-button-content-padding-lr
    padding-bottom: $stepper-button-content-padding-lr

    .md-stepper-indicator
      margin-top: -($stepper-button-content-padding-lr)
      margin-bottom: -($stepper-button-content-padding-lr)

      padding-top: $stepper-button-content-padding-lr
      padding-bottom: $stepper-button-content-padding-lr

    .md-steppers-scope
      margin-left: $stepper-circle-radius
