.diagonal(@x) {
  @result: sqrt(pow(@x, 2) + pow(@x, 2));
}

@cornerSize: 12px;
@cornerMargin: .diagonal(@cornerSize)[@result] / 2;

password-input-helper {
  display: block;

  sui-popover {
    display: block;
    position: relative;
  }

  &.sui-opened {
    z-index: 100 !important;
  }
}

sui-popover-content.password-input-helper-content.sui-corner {
  &.sui-origin-top {
    margin-top: ~'-1 * @cornerMargin !important';
  }

  &.sui-origin-right {
    margin-left: @cornerMargin !important;
  }

  &.sui-origin-bottom {
    margin-top: @cornerMargin !important;
  }

  &.sui-origin-left {
    margin-left: ~'-1 * @cornerMargin !important';
  }
}
