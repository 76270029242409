@import '~angular-material/modules/js/radioButton/radioButton.css'


// Default radioButton styles
// ----------------------------------------------------------------------------
md-radio-button
  margin-bottom: 0
  min-height: 20px

  .md-label
    margin-left: 32px
    vertical-align: baseline

  .md-container
    top: 0
    transform: none

  &:hover:not(.md-checked) .md-off
    border-color: var(--sui-radio_button__hover)

  .md-off
    border-width: 1px
    border-color: var(--sui-radio_dot-border__base)

  .md-on
    background: transparent

  &.md-checked
    .md-off
      border-color: var(--sui-radio_dot__base)

    .md-on
      transform scale(.6)
      background: var(--sui-radio_dot__base) !important

  &[disabled]
    color: var(--sui-radio__disabled)
    cursor not-allowed

    .md-off, .md-on
      border-color: var(--sui-radio__disabled) !important
    .md-on
      background: var(--sui-radio__disabled) !important
