@import "packages/panel-kit/src/lib/panel.module.less";
@import "./app/app.module.less";
@import (reference) '@selectel/ui-kit-ant/styles/index.less';
@import (reference) 'packages/panel-kit/src/lib/modules/layout/components/header/header.component.less';

body {
  overflow: hidden;

  .sui-header-spacer {
    height: var(@panel-header-height);
  }
}
