@import '~angular-material/modules/js/select/select.css';


// Variables
// ----------------------------------------------------------------------------

$select-border-width ?= 1px
$select-focus-shadow ?= 0 0 0 4px var(--sui-select_border__pressed)
$select-min-width ?= 0

$select-small-font ?= 13px
$select-small-height ?= 36px
$select-small-padding ?= 3px 34px 3px 12px

$select-base-font ?= 16px
$select-base-height ?= 36px
$select-base-padding ?= 2px 34px 3px 12px

$select-large-font ?= 16px
$select-large-height ?= 36px
$select-large-padding ?= 5px 34px 6px 12px

overflowSelectedItemBackground($background)
  md-select-value,
  .md-select-value
    & > *:first-child:after
      content: "" !important
      background linear-gradient(to right, transparent 0, $background 33%)

suiSelectSize($height, $font, $padding, $lineHeight = $font + 1px)
  min-height $height !important

  md-select-value,
  .md-select-value
    font-size: $font
    padding: $padding !important
    line-height: $lineHeight


md-input-container
  // invalid
  &.md-input-invalid md-select
    border 1px solid var(--sui-select_border__error)

    md-select-value,
    .md-select-value
      border none !important

  // no float placeholder
  &.md-input-focused:not([md-no-float])
    .md-select-placeholder
      span:first-child
        transform none !important

  // input
  md-select
    margin 0
    position relative
    border-radius $radius-base
    background-color var(--sui-select_background__base)
    // current value of --sui-select_background__base is transparent
    // so we need set color explicitly
    overflowSelectedItemBackground(var(--sui-global_background))

    // add shadow on focus
    &:focus
      box-shadow $select-focus-shadow

    // arrow to bottom
    &:after
      content ''
      display block
      position absolute
      top 50%
      right 10px
      width 7px
      height 7px
      border 1px solid var(--sui-select_arrow__base)
      border-top 0 !important
      border-right 0 !important
      transform rotate(-45deg) translateY(-100%)
      pointer-events none
      z-index 2

    // disabled element
    &[disabled]
      cursor not-allowed !important
      color var(--sui-select_text__disabled)
      border-color var(--sui-select_border__base)
      background-color var(--sui-select_background__disabled)
      overflowSelectedItemBackground(var(--sui-select_background__disabled))


      &:after
        border-color var(--sui-select_arrow__disabled)

      md-select-value,
      .md-select-value
        color var(--sui-select_text__disabled)

    &:not([disabled]):hover

      &:after
        border-color: var(--sui-select_arrow__hover)

      md-select-value,
      .md-select-value
        border-color var(--sui-select_border__hover)

    // selected value
    md-select-value,
    .md-select-value
      margin 0 !important
      color var(--sui-select_text__filled)
      background transparent
      border-color var(--sui-select_border__base)
      border-radius $radius-base
      font-size $select-base-font
      padding $select-base-padding !important
      line-height $select-base-font + 4px
      min-width $select-min-width
      min-height 0 !important
      overflow hidden
      box-shadow none
      border-style solid
      border-width $select-border-width !important

      & > *:first-child
        overflow visible
        position relative

      // overflow gradient
      & > *:first-child:after
        content ''
        display block
        position absolute
        height 100%
        width 34px //left padding
        right -34px
        top 0
        overflow hidden

    .md-select-icon
      display none !important

    suiSelectSize($select-base-height, $select-base-font, $select-base-padding)
    &.m-s
      suiSelectSize($select-small-height, $select-small-font, $select-small-padding)
    &.m-l
      suiSelectSize($select-large-height, $select-large-font, $select-large-padding)


.md-select-menu-container.md-leave
  transition none!important

.md-select-menu-container.md-active md-select-menu {
  border-radius: 6px;
}

md-select-menu

  box-shadow: 0 8px 16px var(--sui-select_dropdown_shadow__first), 0 0 2px var(--sui-select_dropdown_shadow__second)
  max-height 270px
  overflow-y auto
  transition none!important

  md-content
    max-height 270px
    transition none!important
    background var(--sui-global_background);
    scrollbar-gutter stable

    md-option
      color: var(--sui-select_option_text__base)
      transition none !important
      background-color var(--sui-global_background)

      .md-text
        font-size: 16px


    md-option[disabled]
      cursor not-allowed
      color var(--sui-select_option_text__disabled)

    md-option[selected]
      color var(--sui-select_option_text__checked)

    md-option:not([disabled]):hover,
    md-option:not([disabled], [selected]):focus
      background-color var(--sui-select_option_background__hover)

md-select-menu[multiple]
  @media (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)
    md-content
      -webkit-transform: translate3d(0,0,0) // fix for bug in Chrome. Elements with overflow: auto sometimes not painted

  md-option
    color: var(--sui-checkbox_label__base)

    &.md-checkbox-enabled
      .md-icon
        border-width: 1px
        border-style: solid
        border-radius: 4px
        background: var(--sui-checkbox_background__unchecked)
        border-color: var(--sui-checkbox_border__unchecked)

      &:not([disabled]):hover .md-icon
        border-color: var(--sui-checkbox_border__hover)

      &[selected]
        color: var(--sui-checkbox_label__base);

        &:not([disabled]):hover
        &:not([disabled]):focus
          var(--sui-select_option_background__hover);

        .md-icon
          background: var(--sui-checkbox_background__checked)
          border-color: var(--sui-checkbox_border__checked)

          &:after
            left: 5.8px
            top: 1.4px
            border-color: var(--sui-checkbox_check__checked);
            border-radius: 0 0 2px 0;
            transform: rotate(45deg) translateZ(1px)

    &:not([disabled]):hover
    &:not([disabled]):focus
      background-color: var(--sui-select_option_background__hover);
    &[disabled]
      .md-icon
        background-color var(--sui-checkbox_background__disabled)

md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon)
  transform unset
