power-toggle
  user-select: none
  display: block
  width: 72px
  height: 36px
  overflow: hidden

  & > div
    border: 1px solid var(--sui-switch_border__base)

  &.m-small {
    width: 46px
    height: 24px
  }

  .m-small
    width: 46px
    height: 24px

  .md-button
    display: block // to avoid an incorrect vertical alignment
    border-radius: 0
    transition: all ease-out 0.15s
    &:not([disabled]):focus, md-menu-item > .md-button:not([disabled]):focus
      box-shadow: none

    &:not([disabled]):hover
      background-color: rgba($suiColor('base', 's'), .2) !important

    &.m-icon
      height: 34px
      width: 37px
      border-top: none
      border-bottom: none

      md-icon
        margin-top: -2px !important
        margin-left: -1px !important
        transition: color ease-out 0.15s

        &.m-small
          width: 16px
          height: 16px

    &.m-small
      width: 23px
      height: 22px
      min-height 22px
      line-height 22px

  .off
    .md-button
      border-radius: $radius-base 0 0 $radius-base

      md-icon
        color: $suiNewColor('red', '6') !important

      &.m-icon
        border-right: 1px solid var(--sui-switch_border__base)

  .on
    .md-button
      transform: translateX(33px)
      border-radius: 0 $radius-base $radius-base 0

      &.m-small
        transform: translate(21px)

      &.m-icon
        border-left: 1px solid var(--sui-switch_border__base)

      md-icon
        color: $suiNewColor('green', '6') !important

  &[disabled]
    cursor: not-allowed
    border-color: rgba($suiColor('base', 's'), .5)

    &>div
      border-color rgba($suiColor('base', 's'), .5) !important

    [ng-click], [ui-sref], a, button
      cursor: not-allowed

    .on
      .md-button
        transform: translateX(34px)

        &.m-small
          transform: translate(22px)

    .md-button
      &.m-icon
        border-left: 1px solid rgba($suiColor('base', 's'), .5)
        border-right: 1px solid rgba($suiColor('base', 's'), .5)
      md-icon
        color: var(--sui-button_text-color__disabled) !important
        background transparent
