incomplete-account-notice {
  md-stepper .md-steppers {
    background-color: inherit !important;
    .md-steppers-header-region {
      background-color: inherit !important;
      .md-steppers-header {
        background-color: inherit !important;
        .md-stepper-indicator {
          background-color: inherit !important;
            .md-stepper-indicator-wrapper {
            background-color: inherit !important;
          }
        }
      }
    }
  }
}

