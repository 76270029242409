@import './search-bar.component';

search-bar
  display: block;

  input, md-input-container
    width: 100%;

  input.input
    padding-right: 32px;

    &.filled
      padding-right: 64px;

    &.small
      padding-right: 28px;

      &.filled
        padding-right: 56px;

    &.large
      padding-right: 36px;

      &.filled
        padding-right: 72px;

  md-icon
    top: 0 !important;

  .icons
    position: absolute;
    top: 0;
    right: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  .icon
    position: relative;
    height: 100%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

  .icon.close
    margin-left: 0;

    &:after
      content: '';
      display: block;
      width: 1px;
      height: 26px;
      position: absolute;
      top: 5px;
      right: -5px;
      background-color: #d1d1d1;
      pointer-events: none;

  .icon.progress
    width: 20px;

    &.small
      width: 16px;

    &.large
      width: 28px;
