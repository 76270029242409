.md-select-value .hidden-for-value {
  display: none;
}

.dashboard-layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dashboard-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.md-tooltip:has(> .my-inn-tooltip) {
  height: auto !important;
  line-height: 16px;
}

@media (max-width: @screen-sm-max) {
  .dashboard-layout {
    flex-direction: column;
  }

  .dashboard-card {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0 !important;
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  .dashboard-card_button {
    width: 100%;
    height: 40px;
    font-size: 16px;
  }
}

md-dialog {
  min-width: 0 !important;
}

.profile-form-label {
  color: var(--sui-form-label-color);
}

.datepicker-mobile {
  & .md-datepicker-input-container {
    width: 100%;
    max-width: 100% !important;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
