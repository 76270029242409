@import '~angular-material/modules/js/virtualRepeat/virtualRepeat.css'
@import '~angular-material/modules/js/datepicker/datepicker.css'

// Variables
// ----------------------------------------------------------------------------

$datepicker-border-width ?= 1px
$datepicker-base-height ?= 36px
$datepicker-input-width ?= 100px

// Default styles
// ----------------------------------------------------------------------------
md-datepicker
  display: inline-flex
  align-items: center
  justify-content: center
  border: $datepicker-border-width solid var(--sui-datepicker_border__base)
  min-height $datepicker-base-height !important
  border-radius: 6px

  &._md-datepicker-has-triangle-icon
    margin-right: 0

  &.ng-invalid
    border-color: var(--sui-datepicker_border-error__base)

  .md-icon-button
    display: block

  .md-button:focus
    box-shadow: none !important

  &[disabled]
    color: var(--sui-input_color__disabled)
    background-color: var(--sui-datepicker_background__disabled)
    border-color: var(--sui-datepicker_border__disabled)

    .md-input
      background-color: transparent

    &:hover
      cursor: not-allowed

  .md-datepicker-triangle-button
    color: var(--sui-datepicker-suffix_color__base)

    .md-datepicker-expand-triangle
      border-color: var(--sui-datepicker-suffix_color__base)

&.m-s
  .md-datepicker-input
    font-size: 13px

.md-icon-button + .md-datepicker-input-container
  margin-left: 0

.md-datepicker-input-container
  border-bottom none
  margin-left: 12px
  max-width: $datepicker-input-width;

md-input-container:not(.md-input-invalid).md-input-focused .md-input.md-datepicker-input
  box-shadow: none


.md-datepicker-calendar-pane.md-pane-open
  background: var(--sui-global_background)
  border-color: var(--sui-datepicker-panel_border-color)
  border-radius $radius-base

  .md-calendar-day-header
    background transparent
    border-bottom: 1px solid var(--sui-datepicker-panel_border-color)

.md-calendar-date

  &-selection-indicator
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    color: var(--sui-datepicker-panel_date-color__base)

    &:hover
      background-color: var(--sui-datepicker-panel_date-background__hover)
      color: var(--sui-datepicker-panel_date-color__hover)

  &.md-calendar-date-disabled
    color var(--sui-datepicker-panel_date-color__disabled)
    background transparent

  &.md-calendar-date-today
    .md-calendar-date-selection-indicator
      border: 1px solid var(--sui-datepicker-panel_date-border-current__base)

  &.md-calendar-selected-date .md-calendar-date-selection-indicator
    background: var(--sui-datepicker-panel_date-background__selected)
    color: var(--sui-datepicker-panel_date-color__selected)

.md-calendar-day-header
  width 340px
  border-radius: $radius-base $radius-base 0 0

.md-calendar tr:last-child td
  border-bottom-color rgb(238,238,238)

.md-calendar-day-header th
  width 44px !important
  box-sizing content-box

.md-datepicker-button
.md-datepicker-button.md-button
.md-datepicker-triangle-button
.md-datepicker-triangle-button.md-button
  min-height: 24px
  height: 28px !important
  width: 28px !important
  padding: 0 8px !important
  border-radius: 0 !important

.md-datepicker-button
  background: initial !important
  margin: 4px 0 0 4px !important
  top: auto !important

.md-datepicker-triangle-button
  bottom: -5px !important

  &:hover
    background: none !important

._md-datepicker-has-triangle-icon
  padding-right: 12px
  margin-right: 0

md-datepicker .md-datepicker-input-container .md-datepicker-input
  min-width: $datepicker-input-width
  padding: 0 !important
  margin: 0 !important
  border: none !important
  height: auto !important
  line-height: normal !important
  color: var(--sui-input_color__base)

.md-datepicker-expand-triangle
  display block
  position absolute
  top 50%
  right 0
  width 7px
  height 7px
  border 1px solid #111
  border-top 0
  border-right 0
  transform rotate(-45deg) translateY(-80%) translateX(15%)

.md-datepicker-input-mask
  height 0

.md-calendar-scroll-container
  box-shadow none

._md-datepicker-floating-label
  label
    display: block !important
    width: auto !important
    left: 0 !important

  .md-input-message-animation
    margin-left: 0 !important

.md-calendar-scroll-mask
  border-radius: $radius-base

.md-calendar-month-label
  color: var(--sui-datepicker-panel_header-color__base)

  md-icon
    color: var(--sui-datepicker-panel_header-color__base)
