sui-onboarding
  .sui-onboarding-overlay
    position absolute
    z-index 99999
    top 0
    left 0
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.6)
    opacity 0.48

  .sui-onboarding-faded
    background-color #000000
    opacity 0.1

  .sui-onboarding-hidden
    opacity 0

  .sui-onboarding-popup
    cursor default
    position absolute
    z-index 100001
    min-width 300px
    padding 20px
    background-color var(--sui-global_background);
    box-shadow 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 2px rgba(9, 36, 51, 0.12)

  .sui-onboarding-centered
    top 50%
    left 50%
    transform translate(-50%, -50%)
    -webkit-transform translate(-50%, -50%)
    -moz-transform translate(-50%, -50%)
    -ms-transform translate(-50%, -50%)
    -o-transform translate(-50%, -50%)

  .sui-onboarding-absolute
    position absolute


  .sui-onboarding-title
    color var(--sui-typography_base)
    font-size 18px
    font-weight 500
    margin-bottom 8px

  .sui-onboarding-content
    color var(--sui-typography_base)
    margin-bottom 16px

  .sui-onboarding-arrow,
  .sui-onboarding-arrow:after
    position absolute
    display block
    width 0
    height 0
    border-color transparent
    border-style solid

  .sui-onboarding-arrow
    border-width 11px

  .sui-onboarding-arrow:after
    border-width 10px
    content ""

  .sui-onboarding-popup.sui-onboarding-top
    .sui-onboarding-arrow
      top -11px
      left 50%
      margin-left -11px
      border-bottom-color var(--sui-modal-border-color__base);
      border-top-width 0

    .sui-onboarding-arrow:after
      content " "
      top 1px
      margin-left -10px
      border-top-width 0
      border-bottom-color var(--sui-global_background);

  .sui-onboarding-popup.sui-onboarding-bottom
    .sui-onboarding-arrow
      bottom -11px
      left 50%
      margin-left -11px
      border-top-color var(--sui-modal-border-color__base);
      border-bottom-width 0

    .sui-onboarding-arrow:after
      content " "
      bottom 1px
      margin-left -10px
      border-bottom-width 0
      border-top-color var(--sui-global_background);

  .sui-onboarding-popup.sui-onboarding-right
    .sui-onboarding-arrow
      top 50%
      right -11px
      margin-top -11px
      border-left-color var(--sui-modal-border-color__base);
      border-right-width 0

    .sui-onboarding-arrow:after
      content " "
      right 1px
      border-right-width 0
      border-left-color var(--sui-global_background);
      bottom -10px

  .sui-onboarding-popup.sui-onboarding-left
    .sui-onboarding-arrow
      top 50%
      left -11px
      margin-top -11px
      border-right-color var(--sui-modal-border-color__base);
      border-left-width 0

    .sui-onboarding-arrow:after
      content " "
      left 1px
      bottom -10px
      border-left-width 0
      border-right-color var(--sui-global_background);

  .sui-onboarding-popup.sui-onboarding-bottom {
    margin-top -10px
  }

  .sui-onboarding-popup.sui-onboarding-left {
    margin-left 10px
  }

  .sui-onboarding-popup.sui-onboarding-top {
    margin-top 10px
  }

  .sui-onboarding-popup.sui-onboarding-right {
    margin-left -10px
  }
