.sui-alert
  border-radius: $radius-base
  font-size: $text-size-middle
  padding: 16px;
  color: var(--sui-typography_base)
  border: 1px solid transparent

  // 'base', 'brand', 'info', 'danger', 'warning', 'success'
  &[sui-alert=""],
  &[sui-alert="info"],
  &[sui-alert="base"]
  &[sui-alert="brand"]
    background-color: var(--sui-alert_bg-color__info)
    border-color: var(--sui-alert_border-color__info)

  &[sui-alert="danger"]
    background-color: var(--sui-alert_bg-color__danger)
    border-color: var(--sui-alert_border-color__danger)

  &[sui-alert="warning"]
    background-color: var(--sui-alert_bg-color__warning)
    border-color: var(--sui-alert_border-color__warning)

  &[sui-alert="success"]
    background-color: var(--sui-alert_bg-color__success)
    border-color: var(--sui-alert_border-color__success)
