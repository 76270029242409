@import 'variables.less';
@import 'core/style.less';
@import 'core/components/panel-submenu/panel-submenu.less';
@import 'core/components/pagination/style.less';
@import 'core/components/app-info/app-info.less';
@import 'core/components/sui-clipboard/sui-clipboard.less';
@import 'core/components/sui-clipboard-menu-button/sui-clipboard-menu-button.less';
@import 'core/components/novnc-console/console.component.less';
@import 'core/components/password-input-helper/password-input-helper.component.less';
@import 'core/components/area-chart/area-chart.component.less';

@import 'core/directives/context-help/style.less';

@import 'modules/swagger-docs/docs.less';
@import 'modules/swagger-docs/json-formatter.less';
@import 'modules/seido/components/seido-plan-selector/seido-plan-selector.component.less';
@import 'modules/network/hardware/index.less';

@import 'modules/seido/style.less';

.suiAddFont(@family, @weight, @style, @path) {
  @font-face {
    font-family: @family;
    font-weight: @weight;
    font-style: @style;
    src: local('xxx'),
    ~"url('@{path}.ttf') format('truetype')",
    ~"url('@{path}.eot') format('eot')",
    ~"url('@{path}.woff') format('woff')";
  }
}

.suiAddFont('Roboto', 400, normal, '@selectel/ui-kit/src/ui/fonts/Roboto/roboto-400');
.suiAddFont('Roboto', 700, normal, '@selectel/ui-kit/src/ui/fonts/Roboto/roboto-700');
