@import (reference) '@selectel/ui-kit-ant/styles/theme/input/input.variables.less';
@import (reference) '@selectel/ui-kit-ant/styles/theme/form/form.variables.less';

.referral-registration-field {
  max-width: 376px;
  width: 100%;
  display: block !important;
}

.ng-invalid-checkbox .md-icon {
  border-color: #de1212;
}

.untouched {
  border-color: var(@sui-input_border_color__base) !important;
}
